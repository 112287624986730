import {
  ArrowPathIcon,
  CheckIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import { InterviewAgentTypesEnum, ProjectWithInterviewCount } from "app-types";
import copy from "copy-to-clipboard";
import { nanoid } from "nanoid";
import React, { useState } from "react";
import {
  ButtonVariantsEnum,
  IconButton,
  Label,
  ModalVariantsEnum,
  SimpleModal,
  SizesEnum,
} from "ui";
import { useAppDispatch } from "../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { updateProject } from "../projectsSlice";

interface InterviewLinkCopyboxProps {
  inviteLinkUrl: string;
  project: ProjectWithInterviewCount;
  onCopyInterviewLink: () => void;
}

export const InterviewLinkCopybox: React.FC<InterviewLinkCopyboxProps> = ({
  inviteLinkUrl,
  project,
  onCopyInterviewLink,
}) => {
  const dispatch = useAppDispatch();
  const [isCopyingInterviewLink, setIsCopyingInterviewLink] = useState(false);
  const [isRegenerateLinkModalOpen, setIsRegenerateLinkModalOpen] =
    useState(false);
  const [isInterviewLinkSaveInProgress, setIsInterviewLinkSaveInProgress] =
    useState(false);

  const isRecruitingModeProject =
    project.settings.interview_agent_type ===
    InterviewAgentTypesEnum.RECRUITING_INTERVIEWER;

  const handleRegenerateInterviewLink = async () => {
    setIsInterviewLinkSaveInProgress(true);
    try {
      await dispatch(
        updateProject({
          projectId: project.id,
          changes: {
            short_id: nanoid(),
          },
        })
      );

      showNotification(dispatch, {
        id: `interviewlink-${project.id}-regenerated-${new Date().getTime()}`,
        primaryMessage: `Interview link refreshed`,
        type: NotificationTypeEnum.SUCCESS,
      });

      setIsRegenerateLinkModalOpen(false);
    } catch (err) {
      console.error("error regenerating interview link", err);
      showNotification(dispatch, {
        id: `interviewlink-${
          project.id
        }-regenerate-failed-${new Date().getTime()}`,
        primaryMessage: `Failed to refresh interview link`,
        type: NotificationTypeEnum.FAILURE,
      });
    } finally {
      setIsInterviewLinkSaveInProgress(false);
    }
  };

  return (
    <div className="flex flex-col justify-between mb-2">
      <Label size={SizesEnum.SMALL}>Interview link</Label>
      <div className="mt-1 flex flex-row space-x-2">
        <div
          className="rounded-md bg-white py-2 px-2 border border-solid border-slate-300 w-fit flex space-x-2 hover:bg-slate-100 cursor-pointer"
          onClick={() => {
            setIsCopyingInterviewLink(true);
            copy(inviteLinkUrl);

            setTimeout(() => {
              setIsCopyingInterviewLink(false);
            }, 4000);

            onCopyInterviewLink();
          }}
        >
          <span className="text-sm font-medium text-blue-600">
            {inviteLinkUrl}
          </span>
          {isCopyingInterviewLink ? (
            <CheckIcon className="h-5 w-5 text-blue-600" />
          ) : (
            <ClipboardDocumentIcon className="h-5 w-5 text-blue-600" />
          )}
        </div>
        <IconButton
          variant={ButtonVariantsEnum.Secondary}
          icon={<ArrowPathIcon className="h-4 w-5" />}
          onClick={() => setIsRegenerateLinkModalOpen(true)}
        />
      </div>
      {!isRecruitingModeProject && (
        <div className="text-sm mt-2 text-slate-600 space-y-2">
          Anyone you send this link to (via email,in-app popup, live chat, etc.)
          can complete an interview
          {project.is_live ? "" : " once this project is launched"}.{" "}
          <a
            href="https://help.alpharun.com/en/articles/687232"
            className="text-blue-600 underline"
            target="_blank"
          >
            Check out the guide
          </a>{" "}
          for maximizing conversion with your interview link.
        </div>
      )}

      <SimpleModal
        isOpen={isRegenerateLinkModalOpen}
        variant={ModalVariantsEnum.Warning}
        title="Refresh interview link"
        subtitle={`If you continue, your current link (${inviteLinkUrl}) will be disabled, and a new link will be generated. Interviews already conducted using the old link will be preserved.`}
        confirmButtonText="Refresh link"
        onCancel={() => setIsRegenerateLinkModalOpen(false)}
        onConfirm={handleRegenerateInterviewLink}
        isPrimaryButtonLoading={isInterviewLinkSaveInProgress}
      />
    </div>
  );
};
