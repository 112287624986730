import { Menu, Transition } from "@headlessui/react";
import {
  EyeIcon,
  PlusSmallIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import {
  Company,
  HandlerOf,
  ProjectUpdate,
  ProjectWithInterviewCount,
  splitProjectQuestions,
} from "app-types";
import React, { useEffect, useState, type FC } from "react";
import {
  Button,
  ButtonVariantsEnum,
  Label,
  Loader,
  LoaderStylesEnum,
  SizesEnum,
  getInterviewLinkForProject,
  joinClassnames,
} from "ui";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { ProjectDetailsTabsEnum } from "../../pages/projectDetailsPage";
import {
  NotificationTypeEnum,
  showNotification,
} from "../notificationsOverlay/notificationsSlice";
import { TargetingSlideoverVariantsEnum } from "../targeting/targetingSlideover";
import { TargetingSlideoverContainer } from "../targeting/targetingSlideoverContainer";
import { selectTeammate } from "../teammate/teammateSlice";
import { fetchTeammates } from "../teammates/teammatesSlice";
import { AppearanceSettingsSection } from "./projectSettings/appearanceSettingsSection";
import { IncentiveSettingsSection } from "./projectSettings/incentiveSettingsSection";
import { InterviewLinkCopybox } from "./projectSettings/interviewLinkCopybox";
import { OtherSettingsSection } from "./projectSettings/otherSettingsSection";
import { ProjectNotificationsSettingsSection } from "./projectSettings/projectNotificationsSettingsSection";
import { ProjectSettingsQuestionsSection } from "./surveyQuestions/projectSettingsQuestionSection";

enum SurveyModeProjectSettingsTabModalsEnum {
  REGENERATE_INTERVIEW_LINK = "REGENERATE_INTERVIEW_LINK",
  REMOVE_SURVEY = "REMOVE_SURVEY",
}

interface SurveyModeProjectSettingsProps {
  project: ProjectWithInterviewCount;
  setCurrentTab: HandlerOf<ProjectDetailsTabsEnum>;
  interviewLimitSettingRef: React.MutableRefObject<HTMLLabelElement | null>;
  onCopyInterviewLink: () => void;
  onSaveProject: (changes: ProjectUpdate) => void;
  company: Company;
}

export const SurveyModeProjectSettings: FC<SurveyModeProjectSettingsProps> = ({
  project,
  company,
  setCurrentTab,
  interviewLimitSettingRef,
  onCopyInterviewLink,
  onSaveProject,
}) => {
  const dispatch = useAppDispatch();

  // Store the source the user selects to add more participants from
  const [targetingSource, setTargetingSource] = React.useState<
    TargetingSlideoverVariantsEnum | undefined
  >();

  const teammate = useAppSelector(selectTeammate);
  const [demoInterviewLink, setDemoInterviewLink] = useState<string | null>(
    null
  );

  const { complexQuestions, basicQuestions } = splitProjectQuestions(
    project.questions
  );

  // Data loading on initial render
  useEffect(() => {
    dispatch(fetchTeammates());

    // Retrieve the demo interview link for this project
    const getDemoInterviewLink = async () => {
      try {
        const axios = await getAxiosInstanceWithAuth();
        const { data } = await axios.get(
          `/projects/${project.id}/demo-interview-link`
        );

        setDemoInterviewLink(data.link);
      } catch (err) {}
    };

    getDemoInterviewLink();
  }, []);

  const [currentModal, setCurrentModal] =
    useState<SurveyModeProjectSettingsTabModalsEnum>();
  const [isInterviewLinkSaveInProgress, setIsInterviewLinkSaveInProgress] =
    useState(false);
  const [isCopyingInterviewLink, setIsCopyingInterviewLink] = useState(false);
  const inviteLinkUrl = getInterviewLinkForProject(
    import.meta.env.VITE_INTERVIEW_APP_BASE_URL,
    project
  );

  const [isSendingTestInvitationEmail, setIsSendingTestInvitationEmail] =
    useState(false);

  const renderAddParticipantsButton = () => {
    return (
      <Menu as="div" className="relative">
        <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:bg-slate-300 disabled:text-slate-500">
          <PlusSmallIcon className="h-5 w-5 mr-1" />
          Add participants
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute overflow-visible left-0.5 z-10 mt-2 w-48 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
            <Menu.Item>
              {({ active }) => (
                <a
                  className={joinClassnames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700"
                  )}
                  onClick={() =>
                    setTargetingSource(TargetingSlideoverVariantsEnum.CSV)
                  }
                >
                  Add via CSV
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  className={joinClassnames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700"
                  )}
                  onClick={() =>
                    setTargetingSource(TargetingSlideoverVariantsEnum.MANUAL)
                  }
                >
                  Add manually
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  className={joinClassnames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700"
                  )}
                  onClick={() =>
                    window.open(
                      "https://help.alpharun.com/en/articles/1041345",
                      "_blank"
                    )
                  }
                >
                  Add via API/Zapier
                </a>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const maybeRenderProgressBanners = () => {
    // If the project is live, outreach is in progress for all pending interviews.
    const shouldShowOutreachInProgressPill =
      project.is_live && Boolean(project.pending_interview_count);

    const interviewCount = project.interview_count;

    if (!interviewCount && !shouldShowOutreachInProgressPill) return null;

    return (
      <div className="flex space-x-2">
        {shouldShowOutreachInProgressPill && (
          <button
            className="rounded-md bg-sky-50 py-0.5 px-1 border border-solid border-sky-600 w-fit flex items-center justify-center space-x-2 hover:bg-sky-100 cursor-pointer pl-2"
            onClick={() =>
              setTargetingSource(
                TargetingSlideoverVariantsEnum.OUTREACH_PARTICIPANTS
              )
            }
          >
            <div className="relative flex h-3 w-3">
              <span
                className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-800 opacity-75"
                style={{ animationDuration: "2s" }}
              ></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-800"></span>
            </div>
            <span className="text-sm font-medium text-sky-700">
              {`Outreach in progress for ${
                project.pending_interview_count
              } interview${project.pending_interview_count > 1 ? "s" : ""}`}
            </span>
          </button>
        )}
        {Boolean(interviewCount) && (
          <button
            className="rounded-md bg-green-50 py-0.5 px-1 border border-solid border-green-600 w-fit flex space-x-2 hover:bg-green-100 cursor-pointer"
            onClick={() => setCurrentTab(ProjectDetailsTabsEnum.Interviews)}
          >
            <CheckCircleIcon className="h-5 w-5 text-green-700" />
            <span className="text-sm font-medium text-green-700">
              {`Insights available for ${interviewCount} interview${
                interviewCount > 1 ? "s" : ""
              }`}
            </span>
          </button>
        )}
      </div>
    );
  };

  const renderParticipantsSummary = () => {
    const shouldShowPendingParticipantsPill =
      !project.is_live && Boolean(project.pending_interview_count);

    // The project is paused if it's not live and there were some non-empty interviews
    const isPaused = !project.is_live && project.interview_count > 0;

    const getDescriptionText = () => {
      if (project.is_live)
        return "Participants you add will be emailed for interviews. ";

      return `When you ${
        isPaused ? "restart" : "launch"
      } this project, we'll email these participants for interviews. `;
    };

    return (
      <>
        <div className="flex space-x-2">
          {shouldShowPendingParticipantsPill && (
            <Button
              variant={ButtonVariantsEnum.Secondary}
              onClick={() =>
                setTargetingSource(
                  TargetingSlideoverVariantsEnum.PENDING_PARTICIPANTS
                )
              }
            >
              <UserGroupIcon className="h-5 w-5 mr-2" />
              {`${project.pending_interview_count} pending ${
                project.pending_interview_count > 1
                  ? "participants"
                  : "participant"
              }`}
            </Button>
          )}
          {renderAddParticipantsButton()}
        </div>
        <div className="text-sm mt-2 text-slate-600">
          <span>{getDescriptionText()}</span>
          <span
            className="cursor-pointer font-semibold"
            onClick={onSendTestInviteEmailClick}
          >
            Click here
          </span>
          <span> to send yourself a test invitation email.</span>
          {isSendingTestInvitationEmail && (
            <Loader style={LoaderStylesEnum.SPINNER} />
          )}
        </div>
      </>
    );
  };

  const onSendTestInviteEmailClick = async () => {
    // If a send is already in progress, don't trigger another.
    if (isSendingTestInvitationEmail) return;

    setIsSendingTestInvitationEmail(true);
    try {
      const axios = await getAxiosInstanceWithAuth();
      await axios.post(`/projects/${project.id}/send-test-invite`);

      showNotification(dispatch, {
        id: `project-${
          project.id
        }-testinvite-succeeded-${new Date().getTime()}`,
        primaryMessage: `Test invitation email sent to ${teammate?.email}`,
        type: NotificationTypeEnum.SUCCESS,
      });
      setIsSendingTestInvitationEmail(false);
    } catch (err) {
      console.error("error sending test invitation email", err);
      setIsSendingTestInvitationEmail(false);
      showNotification(dispatch, {
        id: `project-${project.id}-testinvite-failed-${new Date().getTime()}`,
        primaryMessage: `Failed to send test invitation email`,
        type: NotificationTypeEnum.FAILURE,
      });
    }
  };

  return (
    <>
      {maybeRenderProgressBanners()}

      <div>
        <Label size={SizesEnum.LARGE}>Questions</Label>
        <ProjectSettingsQuestionsSection
          basicQuestions={basicQuestions}
          complexQuestions={complexQuestions}
          project={project}
          company={company}
          onSaveProject={onSaveProject}
        />
      </div>

      {basicQuestions.length > 0 || complexQuestions.length > 0 ? (
        <div>
          <Button
            variant={ButtonVariantsEnum.Primary}
            onClick={() => {
              if (demoInterviewLink) window.open(demoInterviewLink, "_blank");
            }}
          >
            <EyeIcon className="h-5 w-5 mr-2" />
            Try this AI interview
          </Button>
        </div>
      ) : null}
      <div>
        <div className="flex items-center justify-between">
          <Label size={SizesEnum.LARGE}>Interview Participants</Label>
        </div>
        <InterviewLinkCopybox
          inviteLinkUrl={inviteLinkUrl}
          project={project}
          onCopyInterviewLink={onCopyInterviewLink}
        />
        <div className="flex flex-col justify-between mb-2">
          <Label size={SizesEnum.SMALL} className="mt-2">
            You can also add participants that we should email:
          </Label>
        </div>
        {renderParticipantsSummary()}

        {targetingSource ? (
          <TargetingSlideoverContainer
            project={project}
            variant={targetingSource}
            onClose={() => setTargetingSource(undefined)}
          />
        ) : null}
      </div>
      <IncentiveSettingsSection
        incentiveQuantity={project.incentive_quantity}
        isIncentiveCharityOnly={project.settings.is_incentive_charity_only}
        onIncentiveQuantityChange={(newQuantity) => {
          onSaveProject({ incentive_quantity: newQuantity });
        }}
        onIncentiveCharityOnlyChange={(isIncentiveCharityOnly) => {
          onSaveProject({
            settings: {
              ...project.settings,
              is_incentive_charity_only: isIncentiveCharityOnly,
            },
          });
        }}
      />
      <ProjectNotificationsSettingsSection
        project={project}
        onSaveProject={onSaveProject}
      />
      <AppearanceSettingsSection
        onSaveProject={onSaveProject}
        company={company}
        project={project}
      />
      <OtherSettingsSection
        onSaveProject={onSaveProject}
        company={company}
        project={project}
        interviewLimitSettingRef={interviewLimitSettingRef}
      />
    </>
  );
};
