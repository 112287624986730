import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { DropdownMenu } from "ui";

interface ProjectMoreMenuProps {
  onClickDeleteProject: () => void;
  onClickCopyInterviewLink?: () => void;
  onClickViewDialInstructions?: () => void;
  onClickCopyProjectId: () => void;
  onClickDuplicateProject: () => void;
}

interface MenuItem {
  label: string;
  onClick: () => void;
}

export const ProjectMoreMenu: FC<ProjectMoreMenuProps> = ({
  onClickDeleteProject,
  onClickCopyInterviewLink,
  onClickViewDialInstructions,
  onClickCopyProjectId,
  onClickDuplicateProject,
}) => {
  const menuItems: MenuItem[] = [
    { label: "Copy Interview Link", onClick: onClickCopyInterviewLink },
    {
      label: "View Dialing Instructions",
      onClick: onClickViewDialInstructions,
    },
    { label: "Copy Project ID", onClick: onClickCopyProjectId },
    { label: "Duplicate Project", onClick: onClickDuplicateProject },
    { label: "Delete Project", onClick: onClickDeleteProject },
  ].filter((item): item is MenuItem => item.onClick !== undefined);

  return (
    <Menu as="div" className="relative ml-3">
      <Menu.Button className="relative flex items-center ring-1 ring-inset ring-gray-300 bg-white p-1.5 hover:bg-gray-50 rounded-md shadow-sm text-gray-900 disabled:bg-slate-300 disabled:text-slate-500">
        <span className="absolute -inset-1.5" />
        <span className="sr-only">Open user menu</span>
        <EllipsisVerticalIcon className="w-6 h-6" />
      </Menu.Button>
      <DropdownMenu items={menuItems} />
    </Menu>
  );
};
