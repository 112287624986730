import {
  BasicQuestion,
  Company,
  ComplexQuestion,
  ComplexQuestionInsert,
  ProjectUpdate,
  ProjectWithInterviewCount,
} from "app-types";
import { FC, useState } from "react";
import { Button, ButtonVariantsEnum } from "ui";
import { QuestionsManagerSlideover } from "./questionsManagerSlideover";

interface ProjectSettingsQuestionsSectionProps {
  complexQuestions: (ComplexQuestion | ComplexQuestionInsert)[];
  basicQuestions: BasicQuestion[];
  project: ProjectWithInterviewCount;
  company: Company;
  onSaveProject: (changes: ProjectUpdate) => void;
}

export const ProjectSettingsQuestionsSection: FC<
  ProjectSettingsQuestionsSectionProps
> = ({ complexQuestions, basicQuestions, project, company, onSaveProject }) => {
  const [isQuestionsManagerSlideoverOpen, setIsQuestionsManagerSlideoverOpen] =
    useState(false);

  const questions = [...complexQuestions, ...basicQuestions];

  return (
    <div className="flex flex-col justify-between mb-2">
      {questions.length > 0 ? (
        <div>
          <div
            className="cursor-pointer inline-block group"
            onClick={() => setIsQuestionsManagerSlideoverOpen(true)}
          >
            {questions.map((question, index) => {
              return (
                <div
                  key={`question-${index}`}
                  className={`group-hover:bg-blue-50 border-b border-l border-r max-w-[700px] flex justify-between items-center px-3 py-2 text-sm relative bg-white ${
                    index === 0 ? "border-t rounded-t-lg" : ""
                  } ${
                    index === questions.length - 1
                      ? "border-b rounded-b-lg"
                      : ""
                  }`}
                >
                  <div className="flex items-center">
                    <span className="text-gray-500 mr-2">•</span>
                    <span>{question.question}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="mt-2">
          <Button
            variant={ButtonVariantsEnum.Primary}
            onClick={() => setIsQuestionsManagerSlideoverOpen(true)}
            label="Add interview questions"
          />
        </div>
      )}
      <QuestionsManagerSlideover
        complexQuestions={complexQuestions}
        basicQuestions={basicQuestions}
        project={project}
        company={company}
        onSaveProject={onSaveProject}
        isOpen={isQuestionsManagerSlideoverOpen}
        onClose={() => setIsQuestionsManagerSlideoverOpen(false)}
      />
    </div>
  );
};
