import {
  Company,
  CompanyInvitation,
  CompanyInvitationErrorsEnum,
  LoadingStatesEnum,
  PosthogEventTypesEnum,
  Teammate,
  extractDomainFromEmail,
} from "app-types";
import axios from "axios";
import posthog from "posthog-js";
import { FC, useEffect, useState } from "react";
import {
  Checkbox,
  Input,
  Loader,
  LoaderStylesEnum,
  RadioButtonsGroup,
  TextCarouselLoader,
} from "ui";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { signoutUser } from "../../api/supabaseService";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import {
  postCompanyData,
  selectCompanyLoadingState,
} from "../company/companySlice";
import { putTeammateData } from "../teammate/teammateSlice";

interface OnboardingFormProps {
  teammate: Teammate;
  company: Company | null;
  companyInvitationToken: string | null;
}

/*
 * This component is responsible for collecting information required before using the app:
 * - First and last name (whenever any user signs up)
 * - Accepting an invitation to join a company or creating a new company name (first user in a company OR user was removed from previous company)
 */

const OnboardingForm: FC<OnboardingFormProps> = (props) => {
  const dispatch = useAppDispatch();
  const { teammate, company, companyInvitationToken } = props;

  const [firstName, setFirstName] = useState(teammate.first_name || "");
  const [lastName, setLastName] = useState(teammate.last_name || "");
  const [companyName, setCompanyName] = useState(company?.name || "");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");

  const [companyInvitation, setCompanyInvitation] =
    useState<CompanyInvitation | null>(null);
  const [companyInvitationLoadingState, setCompanyInvitationLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADING);
  const [companyInvitationError, setCompanyInvitationError] =
    useState<CompanyInvitationErrorsEnum | null>(null);
  const [existingPendingInvitationToken, setExistingPendingInvitationToken] =
    useState<string | null>();

  const companyLoadingState = useAppSelector(selectCompanyLoadingState);

  const errorEnumToErrorMessage = {
    [CompanyInvitationErrorsEnum.NOT_FOUND]:
      "Sorry, we couldn't find that invitation. Please ask your admin to invite you again.",
    [CompanyInvitationErrorsEnum.EXPIRED]:
      "This invitation has expired. Please ask your admin to invite you again.",
    [CompanyInvitationErrorsEnum.NO_EMAIL_MATCH]: `The email on this invitation doesn't match the email you've logged in with (${teammate.email}). Please change accounts or ask your admin to invite you again. `,
    [CompanyInvitationErrorsEnum.ALREADY_JOINED]:
      "You've already joined this company.",
  };

  const [domainLoginLoadingState, setDomainLoginLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADING);
  // The name of the company the user is eligible to domain-login to (if any);
  const [domainLoginCompanyName, setDomainLoginCompanyName] =
    useState<string>();
  // Whether the user should domain login to an eligible company. Only relevant if
  // `domainLoginCompanyName` is set.
  const [isJoiningMatchingDomainCompany, setIsJoiningMatchingDomainCompany] =
    useState(false);

  // Whether the user's domain is eligible to be claimed by a new company.
  // If true, this means no other company has claimed the domain and
  // the domain is not a common/free domain.
  const [isDomainAvailable, setIsDomainAvailable] = useState<boolean>(false);
  const [
    shouldEnableDomainLoginForNewCompany,
    setShouldEnableDomainLoginForNewCompany,
  ] = useState(true);

  // If we have an invitation token, fetch it on initial load
  // If we don't have an invitation token, check if there is a company to domain-login to
  useEffect(() => {
    const fetchInvitation = async () => {
      try {
        const axios = await getAxiosInstanceWithAuth();
        const { data } = await axios.get(
          `/company-invitations/invitation-for-token?t=${companyInvitationToken}`
        );

        setCompanyInvitation(data);
        setCompanyInvitationLoadingState(LoadingStatesEnum.LOADED);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          const errorCode = err.response.data.errorCode;

          // If the user is already a member of the company we're viewing an invite for, redirect home
          if (errorCode === CompanyInvitationErrorsEnum.ALREADY_JOINED)
            window.location.href = "/";

          setCompanyInvitationError(errorCode);
        }
        setCompanyInvitationLoadingState(LoadingStatesEnum.ERROR);
      }
    };

    // Checks if there is a pending invitation or matching domain-login company for the teammate.
    const fetchPendingInvitationOrCompanyForTeammate = async () => {
      try {
        const axios = await getAxiosInstanceWithAuth();
        const { data } = await axios.get(`/domains/company-for-teammate`);

        // If there's a pending invite for this user, use it. This case
        // occurs when a user is invited, but signs in without using their invite link.
        if (data && data.pending_invitation) {
          setCompanyInvitation(data.pending_invitation);
          setExistingPendingInvitationToken(data.pending_invitation_token);
          setDomainLoginLoadingState(LoadingStatesEnum.LOADED);
          return;
        }

        if (data) {
          if (data.matching_company_name) {
            setDomainLoginCompanyName(data.matching_company_name);
            setIsJoiningMatchingDomainCompany(true); // Default to domain login
          }

          setIsDomainAvailable(data.is_company_domain_available);
        }

        setDomainLoginLoadingState(LoadingStatesEnum.LOADED);
      } catch (err) {
        setDomainLoginLoadingState(LoadingStatesEnum.ERROR);
      }
    };

    // If we have an invitation token directly from the URL, fetch the corresponding invite.
    if (companyInvitationToken) {
      fetchInvitation();
    } else {
      fetchPendingInvitationOrCompanyForTeammate();
    }
  }, []);

  const onClickSave = async () => {
    const isCreatingNewCompany =
      !companyInvitation && !company && !isJoiningMatchingDomainCompany;
    const missingCompanyName = isCreatingNewCompany && !companyName;

    // We need a first name, last name, and organization name
    if (!firstName) setFirstNameError("First name is required");
    if (!lastName) setLastNameError("Last name is required");
    if (missingCompanyName)
      setCompanyNameError("Please provide your organization name");

    // If any of the fields are missing, let user fill in
    if (!firstName || !lastName || missingCompanyName) return;

    // Update the teammate first name and last name if necessary
    if (teammate.first_name !== firstName || teammate.last_name !== lastName)
      dispatch(
        putTeammateData({
          ...teammate,
          first_name: firstName,
          last_name: lastName,
        })
      );

    // If we're just accepting an invitation, join that company.
    if (companyInvitation) {
      try {
        setCompanyInvitationLoadingState(LoadingStatesEnum.LOADING);
        const axios = await getAxiosInstanceWithAuth();
        await axios.post(
          `/company-invitations/invitation-for-token?t=${
            companyInvitationToken || existingPendingInvitationToken
          }`
        );

        // Reload the app now that we've joined a new company
        window.location.reload();
        return;
      } catch (err) {
        setCompanyInvitationLoadingState(LoadingStatesEnum.ERROR);
        return;
      }
    }

    // If we're creating a new company, create the company with the user specified name
    // and enable domain login if necessary.
    if (isCreatingNewCompany) {
      // Create a new company if necessary
      return dispatch(
        postCompanyData({
          companyName: companyName,
          shouldEnableDomainLogin:
            isDomainAvailable && shouldEnableDomainLoginForNewCompany,
        })
      );
    }

    // Otherwise, we must be joining an existing company via domain login
    if (isJoiningMatchingDomainCompany && domainLoginCompanyName) {
      try {
        const axios = await getAxiosInstanceWithAuth();
        await axios.post(`/domains/company-for-teammate-domain`);

        posthog.capture(PosthogEventTypesEnum.TEAMMATE_DOMAIN_LOGIN, {
          teammate_email: teammate.email,
        });
        // Reload the app now that we've joined a new company
        window.location.href = "/";
        return;
      } catch (err) {
        setDomainLoginLoadingState(LoadingStatesEnum.ERROR);
        return;
      }
    }

    throw new Error("Unhandled case in onClickSave");
  };

  const renderOrganizationOptions = () => {
    if (companyInvitation || company) {
      return null;
    }

    const shouldShowNewOrganizationConfigurationOptions =
      !domainLoginCompanyName ||
      (domainLoginCompanyName && !isJoiningMatchingDomainCompany);

    return (
      <>
        {domainLoginCompanyName && (
          <div className="mt-5">
            <RadioButtonsGroup
              label="Organization"
              options={[
                {
                  name: `${domainLoginCompanyName} (recommended)`,
                  description: `Join the existing organization for @${extractDomainFromEmail(
                    teammate.email
                  )} users.`,
                },
                {
                  name: "New organization",
                  description: "Create your own organization.",
                },
              ]}
              value={
                isJoiningMatchingDomainCompany
                  ? `${domainLoginCompanyName} (recommended)`
                  : "New organization"
              }
              onChange={(value) => {
                setIsJoiningMatchingDomainCompany(
                  value === `${domainLoginCompanyName} (recommended)`
                );
              }}
            />
          </div>
        )}
        {shouldShowNewOrganizationConfigurationOptions && (
          <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-4">
            <div className="sm:col-span-4">
              <Input
                label="Organization name"
                value={companyName}
                onChange={(evt) => {
                  setCompanyNameError("");
                  setCompanyName(evt.target.value);
                }}
                placeholder="Acme"
                errorDescription={companyNameError}
                description={`The name of your company. This will be shown to participants you interview.${
                  domainLoginCompanyName
                    ? ""
                    : " To join an existing organization, request an invite from a teammate first."
                }`}
              />
            </div>

            {isDomainAvailable && (
              <div>
                <label className="block text-sm mb-1 font-medium leading-6 text-gray-900">
                  Domain login
                </label>
                <Checkbox
                  isChecked={shouldEnableDomainLoginForNewCompany}
                  onChange={setShouldEnableDomainLoginForNewCompany}
                  label={`Allow teammates with an @${extractDomainFromEmail(
                    teammate.email
                  )} email address to automatically join your organization.`}
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const renderContent = () => {
    if (companyInvitationToken) {
      if (companyInvitationLoadingState === LoadingStatesEnum.LOADING)
        return (
          <div className="flex flex-col justify-center items-center h-40">
            <Loader style={LoaderStylesEnum.ZOOMIES} />
          </div>
        );

      if (
        companyInvitationLoadingState === LoadingStatesEnum.ERROR ||
        !companyInvitation
      ) {
        const errorMessage = companyInvitationError
          ? errorEnumToErrorMessage[companyInvitationError]
          : "We couldn't find that invitation. Please refresh or request a new invitation from your admin.";

        return <div className="text-xl text-gray-800">{errorMessage}</div>;
      }
    } else {
      if (domainLoginLoadingState === LoadingStatesEnum.LOADING) {
        return (
          <div className="flex flex-col justify-center items-center h-40">
            <Loader style={LoaderStylesEnum.ZOOMIES} />
          </div>
        );
      }

      if (companyLoadingState === LoadingStatesEnum.LOADING) {
        return (
          <div className="flex flex-col justify-center items-center h-40">
            <TextCarouselLoader
              messages={[
                "Creating your account...",
                "Setting up your organization...",
                "Preparing demo project...",
              ]}
            />
            <Loader style={LoaderStylesEnum.ZOOMIES} />
          </div>
        );
      }
    }

    return (
      <div className="mx-auto max-w-2xl">
        <form>
          <div>
            <h1 className="text-2xl font-semibold text-gray-800">
              {companyInvitation
                ? `${companyInvitation.sender_full_name} invited you to join the ${companyInvitation.company_name} team on Alpharun`
                : "Welcome to Alpharun"}
            </h1>
            {companyInvitation && company && (
              <div className="mt-4 text-sm text-orange-600">
                {`Heads up: you're accepting an invite to join ${companyInvitation.company_name} but you already belong to the ${company.name} organization. If you continue you'll lose access to your previous organization.`}
              </div>
            )}
            <p className="mt-4 text-sm leading-6 text-gray-600">
              {"Provide a few details to get started."}
            </p>
            <div className="border-b border-gray-900/10 pb-8">
              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <Input
                    label="Email address"
                    onChange={() => {
                      /* noop for disabled input*/
                    }}
                    value={teammate.email}
                    readOnly
                    isDisabled
                  />
                </div>
              </div>
              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <Input
                    label="First name"
                    value={firstName}
                    onChange={(evt) => {
                      setFirstNameError("");
                      setFirstName(evt.target.value);
                    }}
                    autoComplete="given-name"
                    errorDescription={firstNameError}
                  />
                </div>
                <div className="sm:col-span-3">
                  <Input
                    label="Last name"
                    value={lastName}
                    onChange={(evt) => {
                      setLastNameError("");
                      setLastName(evt.target.value);
                    }}
                    autoComplete="family-name"
                    errorDescription={lastNameError}
                  />
                </div>
              </div>
              {renderOrganizationOptions()}
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={onClickSave}
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <nav className="bg-gray-800">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-end">
            <button
              type="button"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={signoutUser}
            >
              Sign out
            </button>
          </div>
        </div>
      </nav>
      <div className="mx-auto max-w-2xl px-4 py-12 sm:px-6 sm:py-12 lg:px-8">
        <div className="bg-white py-8 px-4 shadow rounded-lg sm:rounded-lg sm:p-10">
          {renderContent()}
        </div>
      </div>
    </>
  );
};

export default OnboardingForm;
