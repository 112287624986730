import type { Dictionary, EntityState } from "@reduxjs/toolkit";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  Account,
  Activity,
  Contact,
  InterviewLoaded,
  InterviewUpdate,
  LoadingStatesEnum,
  TranscriptFragment,
} from "app-types";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { bulkUpdateInterviewsDb } from "../../api/supabaseService";
import { InterviewWithContactAndActivities } from "../../app/admin_client_types";
import type { RootState } from "../../app/store";
import { selectAccountsDictionary } from "../accounts/accountsSlice";
import { selectAllActivities } from "../activities/activitiesSlice";
import { selectContactsDictionary } from "../contacts/contactsSlice";
import { fetchInsightsAsync } from "../insights/insightsSlice";
import { fetchProjectById } from "../projects/projectsSlice";
import { transcriptFragmentsAdapter } from "../transcriptFragments/transcriptFragmentsSlice";

/*
 * State
 */

interface InterviewsParameters {
  projectId: string;
  page: number;
  search_text?: string;
  // In the future add things like date ranges or search filters
}

export const interviewsAdapter = createEntityAdapter<InterviewLoaded>();

const initialState: EntityState<InterviewLoaded> & {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  parameters: InterviewsParameters | undefined;
  totalPages: number | undefined;
  interviewIds: string[]; // The interiews corresponding to the current parameters
  interviewIdToMatchingFragmentId: Record<string, string>;
  focusedInterviewId: string | undefined;
  focusedInterviewLoadingState: LoadingStatesEnum;
} = interviewsAdapter.getInitialState({
  status: "idle",
  error: null,
  parameters: undefined,
  totalPages: undefined,
  interviewIds: [],
  interviewIdToMatchingFragmentId: {},
  focusedInterviewId: undefined,
  focusedInterviewLoadingState: LoadingStatesEnum.LOADED,
});

/*
 * Thunks
 */

interface FetchInterviewsPayload {
  interviews: InterviewLoaded[];
  contacts: Contact[];
  accounts: Account[];
  activities: Activity[];
  matching_transcript_fragments?: TranscriptFragment[];
  total_pages: number;
}

// Fetch all interviews, associated contacts, activities, and the interview link for a particular project
export const fetchNonEmptyInterviewsForProject = createAsyncThunk<
  FetchInterviewsPayload,
  InterviewsParameters,
  { rejectValue: string }
>(
  "interviews/fetchDependenciesForProject",
  async (parameters: InterviewsParameters, thunkAPI) => {
    const { projectId, page, search_text } = parameters;

    try {
      const axios = await getAxiosInstanceWithAuth();

      const params: any = {
        page,
      };
      if (search_text) params.search_text = search_text;

      const response = await axios.get(`/projects/${projectId}/interviews`, {
        params,
      });

      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteInterviews = createAsyncThunk(
  "interviews/deleteInterviews",
  async (
    args: { interviewIds: string[]; projectId: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const deletedInterviews = await bulkUpdateInterviewsDb(
        args.interviewIds,
        {
          deleted_at: new Date().toISOString(),
        }
      );

      // Refetch project to get latest interview counts
      dispatch(fetchProjectById(args.projectId));

      return deletedInterviews.map((interview) => interview.id);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteInterview = createAsyncThunk(
  "interviews/deleteInterview",
  async (
    args: { projectId: string; interviewId: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      await axios.delete(
        `/projects/${args.projectId}/interviews/${args.interviewId}`
      );

      // Refetch project to get latest interview counts
      dispatch(fetchProjectById(args.projectId));

      return args.interviewId;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const bulkUpdateInterviews = createAsyncThunk(
  "interviews/bulkUpdateInterviews",
  async (
    args: {
      interviewIds: string[];
      update: InterviewUpdate;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const result = await bulkUpdateInterviewsDb(
        args.interviewIds,
        args.update
      );

      // Refetch project to get latest interview counts
      if (result.length > 0) dispatch(fetchProjectById(result[0].project_id));

      return result;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchFocusedInterview = createAsyncThunk<
  { contact: Contact; interview: InterviewLoaded; account: Account | null },
  { projectId: string; interviewId: string },
  { rejectValue: string }
>("interviews/fetchFocusedInterview", async (params, thunkAPI) => {
  const { projectId, interviewId } = params;

  try {
    const axios = await getAxiosInstanceWithAuth();
    const response = await axios.get(
      `/projects/${projectId}/interviews/${interviewId}`
    );
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

/*
 * Selectors
 */

export const selectInterviewsForProject = (
  state: RootState,
  projectId: string
) =>
  interviewsAdapter
    .getSelectors()
    .selectAll(state.interviews)
    .filter((interview) => interview.project_id === projectId);

export const buildInterviewsWithContactsAndActivities = (
  interviews: InterviewLoaded[],
  contactsDictionary: Dictionary<Contact>,
  accountsDictionary: Dictionary<Account>,
  allActivities: Activity[]
) => {
  return interviews
    .map((interview) => {
      const associatedContact = contactsDictionary[interview.contact_id];
      const associatedAccount = associatedContact?.account_id
        ? accountsDictionary[associatedContact.account_id]
        : undefined;
      const associatedActivites = allActivities.filter(
        (activity) => activity.interview_id === interview.id
      );

      const { contact_id, ...restOfInterview } = interview;
      return {
        ...restOfInterview,
        contact: {
          ...associatedContact,
          account: associatedAccount || null,
        },
        activities: associatedActivites,
      } as InterviewWithContactAndActivities;
    })
    .filter((i) => i.contact) as InterviewWithContactAndActivities[];
};

// Selects all interviews in the adapter (used by Insights tab)
export const selectNonEmptyInterviewsWithContactsAndActivitiesForProject: (
  state: RootState,
  projectId: string
) => InterviewWithContactAndActivities[] = createSelector(
  [
    (state: RootState, projectId: string) =>
      selectInterviewsForProject(state, projectId),
    (state: RootState) => selectContactsDictionary(state),
    (state: RootState) => selectAccountsDictionary(state),
    (state: RootState) => selectAllActivities(state.activities),
  ],
  buildInterviewsWithContactsAndActivities
);

// Selects interviews corresponding to the current parameters (used to show paginated Interviews tab)
export const selectInterviewsForCurrentParameters = (
  state: RootState
): InterviewLoaded[] => {
  const interviewIds = state.interviews.interviewIds;
  return interviewIds
    .map((id) => {
      return state.interviews.entities[id];
    })
    .filter((i): i is InterviewLoaded => i !== null);
};

export const selectInterviewsForCurrentParametersWithContactsAndActivities: (
  state: RootState
) => InterviewWithContactAndActivities[] = createSelector(
  [
    (state: RootState) => selectInterviewsForCurrentParameters(state),
    // For contacts, get the `contacts` sub-state from the RootState
    (state: RootState) => selectContactsDictionary(state),
    (state: RootState) => selectAccountsDictionary(state),
    (state: RootState) => selectAllActivities(state.activities),
  ],
  buildInterviewsWithContactsAndActivities
);

export const selectFocusedInterviewAndContact: (
  state: RootState
) => { contact: Contact; interview: InterviewLoaded } | null = createSelector(
  [
    (state: RootState) => state.interviews.focusedInterviewId,
    interviewsAdapter.getSelectors((state: RootState) => state.interviews)
      .selectEntities,
    selectContactsDictionary,
  ],
  (focusedInterviewId, interviewsDictionary, contactsDictionary) => {
    if (!focusedInterviewId) return null;

    const interview = interviewsDictionary[focusedInterviewId];
    if (!interview) return null;

    const contact = contactsDictionary[interview.contact_id];
    if (!contact) return null;

    return { contact, interview };
  }
);

export const selectMatchingTranscriptFragmentForInterview: (
  state: RootState,
  interviewId: string
) => TranscriptFragment | undefined = createSelector(
  [
    (state: RootState, interviewId) =>
      state.interviews.interviewIdToMatchingFragmentId[interviewId],
    transcriptFragmentsAdapter.getSelectors(
      (state: RootState) => state.transcriptFragments
    ).selectEntities,
  ],
  (matchingFragmentId, transcriptFragmentsDictionary) => {
    if (!matchingFragmentId) return undefined;

    return transcriptFragmentsDictionary[matchingFragmentId];
  }
);

/*
 * Slice
 */

export const interviewsSlice = createSlice({
  name: "interviews",
  initialState,
  reducers: {
    interviewAdded: interviewsAdapter.addOne,
    interviewsAdded: interviewsAdapter.addMany,
    interviewRemoved: interviewsAdapter.removeOne,
    interviewUpdated: interviewsAdapter.updateOne,
    clearFocusedInterview: (state) => {
      state.focusedInterviewId = undefined;
      state.focusedInterviewLoadingState = LoadingStatesEnum.LOADED;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNonEmptyInterviewsForProject.pending, (state, action) => {
        state.status = "loading";
        state.parameters = action.meta.arg;
      })
      .addCase(fetchNonEmptyInterviewsForProject.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.totalPages = action.payload.total_pages;
        state.interviewIds = action.payload.interviews.map((i) => i.id);

        // Search queries also include list of matching fragments: create a mapping for easy lookups
        if (action.payload.matching_transcript_fragments) {
          const interviewIdToMatchingFragmentId =
            action.payload.matching_transcript_fragments.reduce(
              (acc, fragment) => {
                acc[fragment.interview_id] = fragment.id;
                return acc;
              },
              {} as Record<string, string>
            );
          state.interviewIdToMatchingFragmentId =
            interviewIdToMatchingFragmentId;
        } else {
          state.interviewIdToMatchingFragmentId = {};
        }

        interviewsAdapter.upsertMany(state, action.payload.interviews);
      })
      .addCase(fetchNonEmptyInterviewsForProject.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      .addCase(fetchInsightsAsync.fulfilled, (state, action) => {
        interviewsAdapter.addMany(state, action.payload.interviews);
      })
      .addCase(deleteInterviews.fulfilled, (state, action) => {
        interviewsAdapter.removeMany(state, action.payload);
      })
      .addCase(deleteInterview.fulfilled, (state, action) => {
        interviewsAdapter.removeOne(state, action.payload);
        state.interviewIds = state.interviewIds.filter(
          (id) => id !== action.payload
        );
        delete state.interviewIdToMatchingFragmentId[action.payload];
      })
      .addCase(bulkUpdateInterviews.fulfilled, (state, action) => {
        const updates = action.payload.map((interview) => ({
          id: interview.id,
          changes: interview,
        }));
        interviewsAdapter.updateMany(state, updates);
      })
      .addCase(fetchFocusedInterview.pending, (state, action) => {
        state.focusedInterviewLoadingState = LoadingStatesEnum.LOADING;
        state.focusedInterviewId = action.meta.arg.interviewId;
      })
      .addCase(fetchFocusedInterview.fulfilled, (state, action) => {
        state.focusedInterviewLoadingState = LoadingStatesEnum.LOADED;
        interviewsAdapter.upsertOne(state, action.payload.interview);
      })
      .addCase(fetchFocusedInterview.rejected, (state, action) => {
        state.focusedInterviewLoadingState = LoadingStatesEnum.ERROR;
      });
  },
});

export const {
  interviewAdded,
  interviewsAdded,
  interviewRemoved,
  interviewUpdated,
  clearFocusedInterview,
} = interviewsSlice.actions;
export default interviewsSlice.reducer;

export const selectInterviewsDictionary = (state: RootState) =>
  state.interviews.entities;

export const selectTotalInterviewPages = (state: RootState) =>
  state.interviews.totalPages;

export const selectInterviewParameters = (state: RootState) =>
  state.interviews.parameters;

export const selectInterviewIdToMatchingFragmentId = (state: RootState) =>
  state.interviews.interviewIdToMatchingFragmentId;
