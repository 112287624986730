import { ArrowUpOnSquareIcon, SparklesIcon } from "@heroicons/react/24/outline";
import {
  ComplexAnswer,
  Contact,
  InterviewLoaded,
  LoadingStatesEnum,
  TranscriptFragment,
  getCompanyHasFeatureAccess,
} from "app-types";
import { FC, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  ButtonVariantsEnum,
  Label,
  SizesEnum,
  Slideover,
  TextSkeleton,
} from "ui";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppSelector } from "../../../hooks/hook";
import { PaywallBanner } from "../../billing/paywallBanner";
import {
  selectCompany,
  selectIsCompanyBlocked,
} from "../../company/companySlice";
import { CustomFieldsSection } from "../../contacts/customFieldsSection";
import { AssessmentScore } from "../../insights/assessmentScore";
import { ContactNameWithTime } from "../../insights/contactNameWithTime";
import { TranscriptFragmentText } from "../../insights/transcriptFragmentText";
import { selectSalesforceIntegration } from "../../integrations/integrationsSlice";
import { InterviewAssessmentSection } from "./interviewAssessmentSection";
import { InterviewMoreMenu } from "./interviewMoreMenu";
import { InterviewRecordingSection } from "./interviewRecordingSection";
import { InterviewSummary } from "./interviewSummary";
import { SentimentSection } from "./sentimentSection";
import { ShareInterviewModal } from "./shareInterviewModal";
import { SurveyResponsesSection } from "./surveyResponses/surveyResponsesSection";

const STICKY_HEADER_HEIGHT = 80;

interface InterviewSlideoverProps {
  contact?: Contact;
  interview?: InterviewLoaded;
  onClickClose: () => void;
}

export const InterviewSlideover: FC<InterviewSlideoverProps> = ({
  contact,
  interview,
  onClickClose,
}) => {
  const shouldShow = Boolean(contact && interview);

  const [loadingState, setLoadingState] = useState(LoadingStatesEnum.LOADING);
  const [transcriptFragments, setTranscriptFragments] = useState<
    TranscriptFragment[]
  >([]);
  const [highlightedTranscriptFragment, setHighlightedTranscriptFragment] =
    useState<string | null>(null);

  const [complexAnswers, setComplexAnswers] = useState<ComplexAnswer[]>([]);
  const [recordingIdToUrl, setRecordingIdToUrl] = useState<{
    [key: string]: string;
  }>({});

  const company = useAppSelector(selectCompany);
  const salesforceIntegration = useAppSelector(selectSalesforceIntegration); // TODO: adapt for other providers
  const isCompanyBlocked = useAppSelector(selectIsCompanyBlocked);

  // Refs map for transcript fragments
  const transcriptFragmentRefs = useRef<{ [key: string]: HTMLDivElement }>({});

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [transcriptFragmentToShare, setTranscriptFragmentToShare] =
    useState<TranscriptFragment | null>(null);

  const location = useLocation();

  // Load transcriptFragments for this interview
  useEffect(() => {
    if (!interview) return;

    const fetchInsights = async () => {
      setLoadingState(LoadingStatesEnum.LOADING);

      try {
        const axios = await getAxiosInstanceWithAuth();
        const { data } = await axios.get(
          `/insights/interviews/${interview.id}`
        );

        setTranscriptFragments(data.transcript_fragments);
        setRecordingIdToUrl(data.recording_id_to_url);

        if (data.complex_answers.length > 0) {
          setComplexAnswers(data.complex_answers);
        }

        setLoadingState(LoadingStatesEnum.LOADED);
      } catch (err) {
        setLoadingState(LoadingStatesEnum.ERROR);
      }
    };

    fetchInsights();
  }, [interview?.id]);

  // Jump to fragment if fragment ID is included as a query param.
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const fragmentId = queryParams.get("f");

    if (fragmentId && transcriptFragments.length > 0) {
      jumpToFragmentById(fragmentId);
    }
  }, [location.search, transcriptFragments]);

  const jumpToFragmentById = (fragmentId: string) => {
    if (transcriptFragmentRefs.current[fragmentId]) {
      setHighlightedTranscriptFragment(fragmentId);
      setTimeout(() => setHighlightedTranscriptFragment(null), 5000);
      transcriptFragmentRefs.current[fragmentId].scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const findFragmentFromText = (text: string) => {
    // Convert to lower case and remove any trailing periods.
    const trimmedText = text.toLowerCase().replace(/\.$/, "");

    return transcriptFragments.find(
      (f) =>
        f.text_transcript &&
        f.text_transcript.toLowerCase().includes(trimmedText)
    );
  };

  const jumpToFragmentByText = (text: string) => {
    const fragment = findFragmentFromText(text);

    if (fragment) jumpToFragmentById(fragment.id);
  };

  const onShareSentimentTextClick = (text: string) => {
    const fragment = findFragmentFromText(text);

    if (fragment) {
      setIsShareModalOpen(true);
      setTranscriptFragmentToShare(fragment);
    }
  };

  const renderTranscript = () => {
    if (loadingState === "loading")
      return (
        <div className="w-full mt-5 flex flex-col space-y-5">
          <TextSkeleton />
          <TextSkeleton />
        </div>
      );

    if (loadingState === "error")
      return (
        <div className="mt-2 text-sm text-orange-600">
          An error occurred while loading the transcript. Please refresh and try
          again.
        </div>
      );

    return (
      <>
        {transcriptFragments.map((t, i) => {
          return (
            <div
              key={t.id}
              ref={(el) => {
                if (el) transcriptFragmentRefs.current[t.id] = el;
              }}
              className={`text-sm text-gray-900 ${
                t.is_dynamic_question ? "pl-8" : ""
              }`}
              style={{ scrollMarginTop: STICKY_HEADER_HEIGHT }}
            >
              {t.question ? (
                <div className={`text-gray-700 ${i !== 0 ? "mt-4" : ""} mb-2`}>
                  {t.question}
                </div>
              ) : null}
              <TranscriptFragmentText
                text={t.text_transcript}
                isHighlighted={t.id === highlightedTranscriptFragment}
                onClickShare={() => {
                  setIsShareModalOpen(true);
                  setTranscriptFragmentToShare(t);
                }}
                role={t.role}
              />
            </div>
          );
        })}
      </>
    );
  };

  const renderSlideoverContent = () => {
    if (!interview || !contact) {
      return null;
    }

    const shouldShowSalesforceFields =
      salesforceIntegration &&
      company &&
      getCompanyHasFeatureAccess(company, "salesforce_integration");

    const { assessment_score, summary, sentiment_score } = interview.insights;

    return (
      <div className="flex flex-col px-6">
        <div className="sticky top-0 z-10 py-4 bg-white border-b border-gray-200 flex justify-between">
          <ContactNameWithTime
            contact={contact}
            date={
              interview.started_at ? new Date(interview.started_at) : undefined
            }
            size="large"
          />
          <div className="flex space-x-3 items-center">
            <Button
              variant={ButtonVariantsEnum.Primary}
              onClick={() => setIsShareModalOpen(true)}
              size={SizesEnum.MEDIUM}
              icon={
                <ArrowUpOnSquareIcon
                  className="h-4 w-4 mr-1 text-white-900"
                  aria-hidden="true"
                />
              }
              label="Share interview"
            />
            <InterviewMoreMenu
              interview={interview}
              onClickDeleteInterview={() => {
                onClickClose();
              }}
            />
          </div>
        </div>
        <div className="overflow-auto pb-6">
          {shouldShowSalesforceFields ? (
            <CustomFieldsSection
              contact={contact}
              integration={salesforceIntegration}
            />
          ) : undefined}
          {summary && (
            <>
              <Label size={SizesEnum.SMALL} className="mt-4 mb-2">
                {assessment_score ? "Assessment" : "Summary"}
                <SparklesIcon className="h-4 w-4 ml-1" />
              </Label>
              {assessment_score && (
                <div className="mb-2">
                  <AssessmentScore
                    score={assessment_score}
                    size={SizesEnum.MEDIUM}
                  />
                </div>
              )}
              <div className="text-sm text-gray-900 pl-5">
                <InterviewSummary summary={summary} />
              </div>
            </>
          )}
          {sentiment_score && (
            <>
              <Label size={SizesEnum.SMALL} className="mt-4 mb-2">
                Sentiment <SparklesIcon className="h-4 w-4 ml-1" />
              </Label>
              <SentimentSection
                insights={interview.insights}
                onClickText={jumpToFragmentByText}
                onClickShare={onShareSentimentTextClick}
              />
            </>
          )}
          <InterviewAssessmentSection interview={interview} />
          <InterviewRecordingSection
            recordingIdToUrl={recordingIdToUrl}
            calls={interview.metadata?.calls || []}
          />
          <SurveyResponsesSection
            interviewQuestions={interview.questions}
            complexAnswers={complexAnswers}
          />
          <Label size={SizesEnum.SMALL} className="mt-4 mb-2">
            Full interview transcript
          </Label>
          <div className="flex flex-col">{renderTranscript()}</div>
        </div>
      </div>
    );
  };

  const renderShareModal = () => {
    if (!interview || !contact) {
      return null;
    }

    return (
      <ShareInterviewModal
        isOpen={isShareModalOpen}
        onClose={() => {
          setIsShareModalOpen(false);
          setTranscriptFragmentToShare(null);
        }}
        interview={interview}
        transcriptFragment={transcriptFragmentToShare}
        contact={contact}
      />
    );
  };

  return (
    <Slideover
      onClickClose={onClickClose}
      title={"Interview details"}
      shouldShow={shouldShow}
    >
      {isCompanyBlocked ? (
        <div className="p-6">
          <PaywallBanner />
        </div>
      ) : (
        renderSlideoverContent()
      )}
      {renderShareModal()}
    </Slideover>
  );
};
