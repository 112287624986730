import { FC } from "react";

export interface InterviewSummaryProps {
  summary: string;
}

export const InterviewSummary: FC<InterviewSummaryProps> = ({ summary }) => {
  return (
    <ul className="list-disc">
      {summary.split(". ").map((sentence, index, array) => {
        // Add back period for each sentence except the last one
        const processedSentence =
          index < array.length - 1 ? `${sentence}.` : sentence;
        return (
          <li key={`summary-sentence-${index}`} className={`mb-2`}>
            {processedSentence}
          </li>
        );
      })}
    </ul>
  );
};
