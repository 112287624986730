import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { Assessment, LoadingStatesEnum } from "app-types";
import { Selector } from "react-redux";
import { RootState } from "../../app/store";

export const assessmentsAdapter = createEntityAdapter<Assessment>();

const initialState: EntityState<Assessment> & {
  status: LoadingStatesEnum;
  error: string | null;
} = assessmentsAdapter.getInitialState({
  status: LoadingStatesEnum.LOADING,
  error: null,
});

export const assessmentsSlice = createSlice({
  name: "assessments",
  initialState,
  reducers: {
    setAllAssessments: assessmentsAdapter.setAll,
  },
});

export const { setAllAssessments } = assessmentsSlice.actions;

export default assessmentsSlice.reducer;

export const selectAssessmentById = (
  id: string | undefined | null
): Selector<RootState, Assessment | undefined> =>
  createSelector(
    [(state: RootState) => state.assessments],
    (assessmentsState) =>
      id
        ? assessmentsAdapter.getSelectors().selectById(assessmentsState, id)
        : undefined
  );
