import { LoadingStatesEnum } from "app-types";
import axios from "axios";
import { FC, useEffect, useState } from "react";
import { Button, ButtonVariantsEnum, DefaultSkeleton, Link } from "ui";
import { supabase } from "../../api/supabaseService";
import { imageLinks } from "../../helpers/imageLinks";

interface SignInPageProps {
  isAcceptingInvitation: boolean;
}

enum InstructionVariantsEnum {
  ACCEPTING_INVITATION = "accepting_invitation",
  STANDARD = "standard",
  INSIGHTS_WITH_DOMAIN_LOGIN = "insights_with_domain_login",
  INSIGHTS_WITHOUT_DOMAIN_LOGIN = "insights_without_domain_login",
}

export const SignInPage: FC<SignInPageProps> = ({ isAcceptingInvitation }) => {
  const [instructionVariant, setInstructionVariant] =
    useState<InstructionVariantsEnum>(
      isAcceptingInvitation
        ? InstructionVariantsEnum.ACCEPTING_INVITATION
        : InstructionVariantsEnum.STANDARD
    );
  const [loginInstructionLoadingState, setLoginInstructionLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADING);

  const queryParams = new URLSearchParams(window.location.search);
  const interviewContactName = queryParams.get("n");

  const fetchLoginInstructions = async (projectId: string) => {
    try {
      const { data } = await axios.get(
        `${
          import.meta.env.VITE_API_BASE_URL
        }/domain-login-status?project_id=${projectId}`
      );

      setInstructionVariant(
        data.is_domain_login_available
          ? InstructionVariantsEnum.INSIGHTS_WITH_DOMAIN_LOGIN
          : InstructionVariantsEnum.INSIGHTS_WITHOUT_DOMAIN_LOGIN
      );
      setLoginInstructionLoadingState(LoadingStatesEnum.LOADED);
    } catch (err) {
      // If login instruction check fails, the standard login UI be used.
      setLoginInstructionLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  useEffect(() => {
    // Try to get the project ID from the URL of a project or interview page.
    const projectId = extractProjectIdFromProjectOrInterviewPage();

    // Only check for domain login possibility if we are on a project or interview page
    // (i.e. the user is trying to access a link to insights)
    if (projectId) {
      fetchLoginInstructions(projectId);
    } else {
      setLoginInstructionLoadingState(LoadingStatesEnum.LOADED);
    }
  }, []);

  const insightsActionText = interviewContactName
    ? `view the full interview with ${interviewContactName}.`
    : "view interview insights.";
  const computeSubtitle = () => {
    switch (instructionVariant) {
      case InstructionVariantsEnum.ACCEPTING_INVITATION:
        return "You've been invited to join your team on Alpharun. Sign in to continue.";
      case InstructionVariantsEnum.INSIGHTS_WITH_DOMAIN_LOGIN:
        return `Sign in to ${insightsActionText}`;
      case InstructionVariantsEnum.INSIGHTS_WITHOUT_DOMAIN_LOGIN:
        return `Please sign in to ${insightsActionText}`;
      default:
        return "Please sign in to continue.";
    }
  };

  const maybeRenderBottomMessage = () => {
    switch (instructionVariant) {
      case InstructionVariantsEnum.ACCEPTING_INVITATION:
      case InstructionVariantsEnum.INSIGHTS_WITH_DOMAIN_LOGIN:
        return (
          <div className="mt-4 text-center text-sm text-gray-600">
            {
              "Don't have an account yet? Sign in with your work email to join your team. Accounts for teammates are free."
            }
          </div>
        );
      case InstructionVariantsEnum.INSIGHTS_WITHOUT_DOMAIN_LOGIN:
        return (
          <div className="mt-4 text-center text-sm text-gray-600">
            {
              "Don't have an account yet? Request one from your Alpharun admin. Accounts for teammates are free."
            }
          </div>
        );
      default:
        return (
          <p className="mt-4 text-center text-sm text-gray-600">
            {"Don't have an account yet? "}
            <a
              href="https://cal.frontapp.com/alpharun/paul/41a63af7" // TODO: replace with something scalable
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              {"Book a demo."}
            </a>
          </p>
        );
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-50 items-center justify-center px-4 sm:px-6 lg:px-8">
      <img
        src={imageLinks.LOGO_BLUE}
        alt="Alpharun"
        className="mx-auto h-4 fixed top-8"
      />
      <div className="w-full max-w-md">
        <div className="bg-white py-10 px-6 shadow rounded-lg sm:rounded-lg">
          {loginInstructionLoadingState === LoadingStatesEnum.LOADING ? (
            <DefaultSkeleton />
          ) : (
            <>
              <h2 className="mb-2 text-center text-2xl font-semibold text-gray-900">
                {instructionVariant ===
                InstructionVariantsEnum.ACCEPTING_INVITATION
                  ? "Join your team on Alpharun"
                  : "Welcome"}
              </h2>
              <div className="mb-4 text-center text-sm text-gray-600">
                {computeSubtitle()}
              </div>
              <div className="flex flex-col items-center space-y-4">
                <Button
                  className="w-52 justify-center grid grid-cols-[auto,1fr]"
                  onClick={() => {
                    supabase.auth.signInWithOAuth({
                      provider: "google",
                      options: {
                        redirectTo: window.location.href.split("#")[0], // Remove # from the URL to prevent redirect issues
                        queryParams: {
                          // Ensures that user is always able to choose the google account they want to log in with, rather than the last account being remembered
                          prompt: "select_account",
                        },
                      },
                    });
                  }}
                  variant={ButtonVariantsEnum.Secondary}
                >
                  <svg
                    className="w-5 h-5 mr-2" // Adjust the size as needed
                    viewBox="0 0 48 48"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Google Logo</title>
                    <clipPath id="g">
                      <path d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z" />
                    </clipPath>
                    <g clipPath="url(#g)">
                      <path fill="#FBBC05" d="M0 37V11l17 13z" />
                      <path fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z" />
                      <path fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z" />
                      <path fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" />
                    </g>
                  </svg>
                  <span>
                    {instructionVariant ===
                    InstructionVariantsEnum.ACCEPTING_INVITATION
                      ? "Sign up with Google"
                      : "Sign in with Google"}
                  </span>
                </Button>

                <Button
                  className="w-52 justify-center grid grid-cols-[auto,1fr]"
                  onClick={() => {
                    supabase.auth.signInWithOAuth({
                      provider: "azure",
                      options: {
                        scopes: "email",
                        redirectTo: window.location.href.split("#")[0], // Remove # from the URL to prevent redirect issues
                        queryParams: {
                          // Ensures that user is always able to choose the account they want to log in with, rather than the last account being remembered
                          prompt: "select_account",
                        },
                      },
                    });
                  }}
                  variant={ButtonVariantsEnum.Secondary}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mr-2"
                    viewBox="0 0 23 23"
                  >
                    <path fill="#f35325" d="M0 0h10v10H0z" />
                    <path fill="#81bc06" d="M11 0h10v10H11z" />
                    <path fill="#05a6f0" d="M0 11h10v10H0z" />
                    <path fill="#ffba08" d="M11 11h10v10H11z" />
                  </svg>
                  <span>
                    {instructionVariant ===
                    InstructionVariantsEnum.ACCEPTING_INVITATION
                      ? "Sign up with Microsoft"
                      : "Sign in with Microsoft"}
                  </span>
                </Button>
              </div>
              {maybeRenderBottomMessage()}
            </>
          )}
        </div>
      </div>
      <div className="absolute bottom-3 w-full px-4">
        <p className="text-center text-xs text-gray-500">
          By signing in, you agree to Alpharun's{" "}
          <Link href={"https://assets.alpharun.com/terms_of_service.pdf"}>
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link href={"https://assets.alpharun.com/privacy_policy.pdf"}>
            Privacy Policy
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

function extractProjectIdFromProjectOrInterviewPage() {
  // Regular expression for a UUID
  const uuidRegex =
    "[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}";

  // Regular expression for the URL path
  // It matches either /project/{UUID} or /project/{UUID}/interviews/{UUID}
  // and captures the first UUID
  const urlPathRegex = new RegExp(
    `^/project/(${uuidRegex})(?:/interviews/${uuidRegex})?$`
  );

  const match = window.location.pathname.match(urlPathRegex);
  return match ? match[1] : null;
}
