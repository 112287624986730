import { TrashIcon } from "@heroicons/react/24/outline";
import {
  Company,
  LoadingStatesEnum,
  getCompanyHasFeatureAccess,
  getLogoUrl,
} from "app-types";
import { FC, useState } from "react";
import { Button, ButtonVariantsEnum, FileUpload } from "ui";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch } from "../../../hooks/hook";
import { UpsellBanner } from "../../billing/upsellBanner";
import { setCompany } from "../../company/companySlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";

interface CompanyLogoEditorProps {
  company: Company;
}

export const CompanyLogoEditor: FC<CompanyLogoEditorProps> = ({ company }) => {
  const dispatch = useAppDispatch();

  const [uploadLoadingState, setUploadLoadingState] = useState(
    LoadingStatesEnum.LOADED
  );
  const [deleteLoadingState, setDeleteLoadingState] = useState(
    LoadingStatesEnum.LOADED
  );

  const logoUrl = company.settings.logo_file_name
    ? getLogoUrl(
        import.meta.env.VITE_SUPABASE_APP_URL,
        company.settings.logo_file_name
      )
    : null;

  const onFileUpload = async (file: File) => {
    setUploadLoadingState(LoadingStatesEnum.LOADING);
    const formData = new FormData();
    formData.append("logo", file);

    try {
      const axios = await getAxiosInstanceWithAuth();

      // Remove the existing logo if necessary
      if (logoUrl) {
        await axios.delete(`/company/logo`);
      }

      const { data } = await axios.post(`/company/logo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setUploadLoadingState(LoadingStatesEnum.LOADED);
      dispatch(
        setCompany({
          ...company,
          settings: {
            ...company.settings,
            logo_file_name: data.logo_file_name,
          },
        })
      );
      return data;
    } catch (error) {
      showNotification(dispatch, {
        id: `logo-error-${new Date().getTime()}`,
        primaryMessage: `An error occurred - please try again`,
        type: NotificationTypeEnum.FAILURE,
      });

      setUploadLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  const onRemoveLogo = async () => {
    setDeleteLoadingState(LoadingStatesEnum.LOADING);

    try {
      const axios = await getAxiosInstanceWithAuth();
      await axios.delete(`/company/logo`);
      setDeleteLoadingState(LoadingStatesEnum.LOADED);
      dispatch(
        setCompany({
          ...company,
          settings: { ...company.settings, logo_file_name: null },
        })
      );
    } catch (error) {
      showNotification(dispatch, {
        id: `logo-error-${new Date().getTime()}`,
        primaryMessage: `An error occurred - please try again`,
        type: NotificationTypeEnum.FAILURE,
      });

      setDeleteLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  const isMissingFeatureAccess = !getCompanyHasFeatureAccess(
    company,
    "whitelabeling"
  );

  return (
    <div>
      {logoUrl ? (
        <div className="my-2">
          <img
            src={logoUrl}
            alt="Company Logo"
            className="max-w-[150px] max-h-[50px]"
          />
        </div>
      ) : null}
      <div className="flex flex-row gap-3 mt-2">
        <FileUpload
          onUpload={onFileUpload}
          isLoading={uploadLoadingState === LoadingStatesEnum.LOADING}
          label={logoUrl ? "Change logo" : "Upload logo"}
          isDisabled={isMissingFeatureAccess}
        />
        {logoUrl ? (
          <Button
            icon={<TrashIcon className="w-4 h-4 mr-2" />}
            variant={ButtonVariantsEnum.Warning}
            onClick={onRemoveLogo}
            label="Remove logo"
            isLoading={deleteLoadingState === LoadingStatesEnum.LOADING}
          />
        ) : null}
      </div>
      <div className="mt-2 text-sm text-gray-500">
        This logo (.png or .jpeg) will be displayed on the welcome screen for
        all interviews. We recommend an image width of at least 100px and aspect
        ratio between 1:1 to 4:1.
      </div>
      {isMissingFeatureAccess ? (
        <div className="mt-2">
          <UpsellBanner planFeatureName="whitelabeling" />
        </div>
      ) : null}
    </div>
  );
};
