import { Menu } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  BasicQuestion,
  ComplexQuestion,
  ComplexQuestionInsert,
  ComplexQuestionTypeToString,
  isComplexProjectQuestion,
  isComplexProjectQuestionInsert,
  isValidComplexQuestion,
} from "app-types";
import { FC } from "react";
import {
  ButtonVariantsEnum,
  DropdownItem,
  DropdownMenu,
  Pill,
  PillColorsEnum,
  SizesEnum,
  getIconButtonClassNames,
  joinClassnames,
} from "ui";

interface QuestionsManagerCellProps {
  questionNumber: number;
  question: ComplexQuestion | ComplexQuestionInsert | BasicQuestion;
  isSelected: boolean;
  onClick: () => void;
  onRemove: () => void;
  onClickMoveUp?: () => void;
  onClickMoveDown?: () => void;
}

export const QuestionsManagerCell: FC<QuestionsManagerCellProps> = ({
  questionNumber,
  question,
  isSelected,
  onClick,
  onRemove,
  onClickMoveUp,
  onClickMoveDown,
}) => {
  const dropdownItems: DropdownItem[] = [];
  if (onClickMoveUp)
    dropdownItems.push({
      label: "Move question up",
      onClick: onClickMoveUp,
    });

  if (onClickMoveDown)
    dropdownItems.push({
      label: "Move question down",
      onClick: onClickMoveDown,
    });

  dropdownItems.push({
    label: "Remove question",
    onClick: onRemove,
  });

  // Check if there's an error with this question
  const isComplexQuestion =
    isComplexProjectQuestionInsert(question) ||
    isComplexProjectQuestion(question);
  const hasError = isComplexQuestion && !isValidComplexQuestion(question, true);

  return (
    <div
      className={joinClassnames(
        "group relative flex flex-row space-x-3 p-3 cursor-pointer border-t border-gray-900/5",
        isSelected ? "bg-blue-100" : " hover:bg-blue-50"
      )}
      onClick={onClick}
    >
      <Pill
        label={questionNumber}
        color={PillColorsEnum.GREY}
        size={SizesEnum.SMALL}
      />
      <div>
        <div
          className={`text-sm`}
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {question.question}
        </div>
        {hasError ? (
          <div className="text-orange-500 flex flex-row items-center mt-1">
            <ExclamationCircleIcon className="inline h-5 w-5 mr-1" />
            <span>{"Invalid question"}</span>
          </div>
        ) : null}
        {isComplexQuestion ? (
          <div className="mt-2">
            <Pill
              label={ComplexQuestionTypeToString[question.type]}
              color={PillColorsEnum.YELLOW}
              size={SizesEnum.SMALL}
            />
          </div>
        ) : undefined}
      </div>

      <Menu
        as="div"
        className="absolute z-10 right-3 top-1/2 transform -translate-y-1/2 ml-3"
        onClick={(evt) => evt.stopPropagation()}
      >
        {({ open }) => (
          <>
            <div
              className={joinClassnames(
                open ? "opacity-100" : "opacity-0 group-hover:opacity-100"
              )}
            >
              <Menu.Button
                onClick={(evt) => {
                  evt.stopPropagation();
                }}
                className={getIconButtonClassNames(
                  ButtonVariantsEnum.Secondary
                )}
              >
                <EllipsisVerticalIcon className="h-4 w-4" />
              </Menu.Button>
            </div>
            <DropdownMenu items={dropdownItems} />
          </>
        )}
      </Menu>
    </div>
  );
};
