import {
  InterviewAgentTypesEnum,
  Project,
  ProjectDetailsTabPathsEnum,
  buildProjectUrlPath,
} from "app-types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Input } from "ui";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import {
  fetchNonEmptyInterviewsForProject,
  selectInterviewsForCurrentParametersWithContactsAndActivities,
} from "./interviewsSlice";
import { InterviewsTable } from "./interviewsTable";

interface InterviewsTabProps {
  project: Project;
}

export const InterviewsTab: React.FC<InterviewsTabProps> = ({ project }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchQuery, setSearchQuery] = useState("");

  const interviews = useAppSelector(
    selectInterviewsForCurrentParametersWithContactsAndActivities
  );
  const interviewsStatus = useAppSelector((state) => state.interviews.status);

  return (
    <div className="h-full pt-4 flex flex-col">
      <div className="flex flex-row space-x-2 mb-4">
        <div className="w-[300px]">
          <Input
            placeholder="Search interview transcripts..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(evt) => {
              if (evt.key === "Enter") {
                evt.preventDefault();
                dispatch(
                  fetchNonEmptyInterviewsForProject({
                    projectId: project.id,
                    page: 1,
                    search_text: searchQuery,
                  })
                );
                (evt.target as HTMLElement).blur();
              }
            }}
            onClickClearButton={(evt) => {
              evt.preventDefault();
              setSearchQuery("");
              dispatch(
                fetchNonEmptyInterviewsForProject({
                  projectId: project.id,
                  page: 1,
                  search_text: "",
                })
              );
              (evt.target as HTMLElement).blur();
            }}
            leftIcon={<MagnifyingGlassIcon className="h-4 w-4 text-gray-700" />}
            maxLength={200}
          />
        </div>
      </div>
      <InterviewsTable
        interviews={interviews}
        isLoading={interviewsStatus === "loading"}
        onInterviewClick={(
          interviewId: string,
          transcriptFragmentId?: string
        ) => {
          navigate(
            buildProjectUrlPath(
              project.id,
              ProjectDetailsTabPathsEnum.Interviews,
              interviewId,
              transcriptFragmentId
            )
          );
        }}
        isRecruitingProject={
          project.settings.interview_agent_type ===
          InterviewAgentTypesEnum.RECRUITING_INTERVIEWER
        }
      />
    </div>
  );
};
