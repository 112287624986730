import { InterviewLoaded } from "app-types";
import { FC } from "react";
import { useAppSelector } from "../../../hooks/hook";
import { selectAssessmentById } from "../../assessments/assessmentsSlice";
import { selectProjectById } from "../../projects/projectsSlice";
import { InterviewAssessmentRating } from "./interviewAssessmentRating";

interface InterviewAssessmentSectionProps {
  interview: InterviewLoaded;
}

export const InterviewAssessmentSection: FC<
  InterviewAssessmentSectionProps
> = ({ interview }) => {
  const project = useAppSelector(selectProjectById(interview.project_id));
  const assessment = useAppSelector(
    selectAssessmentById(project?.assessment_id)
  );

  if (!assessment) {
    return null;
  }

  return (
    <div className="overflow-hidden border border-gray-200 sm:rounded-lg mt-4">
      <table className="min-w-full divide-y divide-gray-200 table-fixed w-full">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="w-[25%] px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Criteria
            </th>
            <th
              scope="col"
              className="w-[15%] px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Rating
            </th>
            <th
              scope="col"
              className="w-[60%] px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Comments
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {assessment.custom_field_definitions.map((fieldDefinition) => {
            const value = interview.custom_fields.find(
              (cf) => cf.custom_field_definition_id === fieldDefinition.id
            );

            return (
              <tr key={fieldDefinition.id} className="group relative">
                <td className="px-4 py-2 text-sm text-gray-800">
                  {fieldDefinition.display_name}
                </td>
                <td className="px-4 py-2 text-sm text-gray-800">
                  <InterviewAssessmentRating rating={value?.data.value} />
                </td>
                <td className="px-4 py-2 text-sm text-gray-800">
                  {value?.data.display_value}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
