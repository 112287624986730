'use client'
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/Tooltip/tooltip.tsx
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
var InfoTooltip = ({
  id,
  place,
  children,
  content,
  delay
}) => {
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx("span", { "data-tooltip-id": id, children: children || /* @__PURE__ */ jsx(InformationCircleIcon, { className: "h-5 w-5 ", "aria-hidden": "true" }) }),
    /* @__PURE__ */ jsx(
      Tooltip,
      {
        id,
        place,
        delayShow: delay,
        style: {
          borderRadius: "6px",
          backgroundColor: "#0f172a",
          padding: "8px 10px",
          zIndex: 50,
          maxWidth: "300px"
        },
        children: content
      }
    )
  ] });
};

// src/helpers/helpers.ts
var joinClassnames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};
var isValidName = (name) => {
  return name.length > 0 && name.length < 36;
};
var toHumanReadableString = (str) => {
  let result = str.replace(/_/g, " ");
  result = result.replace(/([a-z])([A-Z])/g, "$1 $2");
  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};
var capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
var getInitials = (email, firstName, lastName) => {
  const firstInitial = (firstName == null ? void 0 : firstName.charAt(0)) || (email == null ? void 0 : email.charAt(0));
  if (!firstInitial)
    return "";
  const secondInitial = lastName == null ? void 0 : lastName.charAt(0);
  return `${firstInitial}${secondInitial || ""}`;
};
var getInterviewLinkForProject = (interviewAppBaseUrl, project) => {
  return `${interviewAppBaseUrl}/${project.short_id}`;
};
var SizesEnum = /* @__PURE__ */ ((SizesEnum2) => {
  SizesEnum2["SMALL"] = "small";
  SizesEnum2["MEDIUM"] = "medium";
  SizesEnum2["LARGE"] = "large";
  return SizesEnum2;
})(SizesEnum || {});

// src/Buttons/button.tsx
import { Fragment as Fragment2, jsx as jsx2, jsxs as jsxs2 } from "react/jsx-runtime";
var ButtonVariantsEnum = /* @__PURE__ */ ((ButtonVariantsEnum2) => {
  ButtonVariantsEnum2["Primary"] = "PRIMARY";
  ButtonVariantsEnum2["Secondary"] = "SECONDARY";
  ButtonVariantsEnum2["Tertiary"] = "TERTIARY";
  ButtonVariantsEnum2["Warning"] = "WARNING";
  return ButtonVariantsEnum2;
})(ButtonVariantsEnum || {});
var Button = (props) => {
  const {
    label,
    onClick,
    isDisabled,
    size,
    variant,
    icon,
    tooltip,
    className
  } = props;
  const sharedClasses = "flex flex-row items-center truncate max-w-full";
  const generateStyleForVariant = () => {
    switch (variant) {
      case "PRIMARY" /* Primary */:
        return "rounded-md bg-indigo-600 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-300 disabled:text-slate-500";
      case "SECONDARY" /* Secondary */:
        return "rounded-md bg-white font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:bg-slate-300 disabled:text-slate-500";
      case "TERTIARY" /* Tertiary */:
        return "font-semibold leading-6 text-gray-500 hover:text-gray-900 disabled:text-gray-500";
      case "WARNING" /* Warning */:
        return "rounded-md bg-white font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:bg-slate-300 disabled:text-slate-500";
    }
  };
  const generateStyleForSize = () => {
    switch (size) {
      case "small" /* SMALL */:
        return "px-2 py-2 text-xs";
      case "large" /* LARGE */:
        return "px-4 py-3 text-base";
      case "medium" /* MEDIUM */:
      default:
        return variant !== "TERTIARY" /* Tertiary */ ? "px-3 py-2 text-sm" : "text-sm";
    }
  };
  const button = /* @__PURE__ */ jsxs2(
    "button",
    {
      className: joinClassnames(
        generateStyleForVariant(),
        generateStyleForSize(),
        sharedClasses,
        props.pulse ? "animate-pulse" : "",
        className ? className : ""
      ),
      disabled: isDisabled,
      onClick,
      type: "button",
      children: [
        props.isLoading && /* @__PURE__ */ jsxs2(
          "svg",
          {
            "aria-hidden": "true",
            role: "status",
            className: "flex-shrink-0 inline w-4 h-4 mr-3 text-white animate-spin",
            viewBox: "0 0 100 101",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
            children: [
              /* @__PURE__ */ jsx2(
                "path",
                {
                  d: "M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z",
                  fill: "#E5E7EB"
                }
              ),
              /* @__PURE__ */ jsx2(
                "path",
                {
                  d: "M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z",
                  fill: "currentColor"
                }
              )
            ]
          }
        ),
        props.children ? props.children : /* @__PURE__ */ jsxs2(Fragment2, { children: [
          icon && /* @__PURE__ */ jsx2("div", { className: "flex-shrink-0", children: icon }),
          label && /* @__PURE__ */ jsx2("div", { className: "flex-grow truncate", children: label })
        ] })
      ]
    }
  );
  if (tooltip) {
    return /* @__PURE__ */ jsx2(InfoTooltip, { id: tooltip.id, place: "right", content: tooltip.content, children: button });
  }
  return button;
};

// src/Buttons/iconButton.tsx
import { jsx as jsx3 } from "react/jsx-runtime";
var IconButton = ({
  icon,
  onClick,
  isDisabled,
  variant,
  tabIndex
}) => {
  return /* @__PURE__ */ jsx3(
    "button",
    {
      className: getIconButtonClassNames(variant),
      onClick,
      disabled: isDisabled,
      tabIndex: tabIndex || void 0,
      children: icon
    }
  );
};
var baseClasses = "cursor-pointer p-1 rounded-md text-gray-900 disabled:bg-slate-300 disabled:text-slate-500";
var variantClasses = {
  ["SECONDARY" /* Secondary */]: "ring-1 ring-gray-300 bg-white shadow-sm hover:bg-gray-50",
  ["TERTIARY" /* Tertiary */]: "hover:bg-slate-200"
};
var getIconButtonClassNames = (variant) => {
  return joinClassnames(baseClasses, variantClasses[variant]);
};

// src/Buttons/radioButtonsGroup.tsx
import { RadioGroup } from "@headlessui/react";
import { Fragment as Fragment3, jsx as jsx4, jsxs as jsxs3 } from "react/jsx-runtime";
var RadioButtonsGroup = ({
  label,
  options,
  value,
  onChange,
  size,
  isDisabled
}) => {
  return /* @__PURE__ */ jsxs3(
    RadioGroup,
    {
      onChange: (value2) => {
        if (value2 && onChange) {
          onChange(value2);
        }
      },
      disabled: Boolean(isDisabled),
      value,
      children: [
        label ? /* @__PURE__ */ jsx4("label", { className: "block text-sm font-medium leading-6 text-gray-900", children: label }) : null,
        /* @__PURE__ */ jsx4("div", { className: `${label ? "mt-2" : ""} -space-y-px rounded-md bg-white`, children: options.map((option, index) => /* @__PURE__ */ jsx4(
          RadioGroup.Option,
          {
            className: ({ checked }) => joinClassnames(
              index === 0 ? "rounded-tl-md rounded-tr-md" : "",
              index === options.length - 1 ? "rounded-bl-md rounded-br-md" : "",
              checked ? "z-10 border-indigo-200 bg-indigo-50" : "border-gray-200",
              size === "medium" /* MEDIUM */ ? "p-2" : "p-2 sm:p-4",
              isDisabled ? "" : "cursor-pointer",
              "relative flex border focus:outline-none items-center"
            ),
            value: option.name,
            children: ({ active, checked }) => /* @__PURE__ */ jsxs3(Fragment3, { children: [
              /* @__PURE__ */ jsx4(
                "span",
                {
                  "aria-hidden": "true",
                  className: joinClassnames(
                    checked ? "bg-indigo-600 border-transparent" : "bg-white border-gray-300",
                    active ? "ring-2 ring-offset-2 ring-indigo-600" : "",
                    isDisabled ? "" : "cursor-pointer",
                    "mt-0.5 h-4 w-4 shrink-0 rounded-full border flex items-center justify-center"
                  ),
                  children: /* @__PURE__ */ jsx4("span", { className: "rounded-full bg-white w-1.5 h-1.5" })
                }
              ),
              /* @__PURE__ */ jsxs3("span", { className: "ml-3 flex flex-col", children: [
                /* @__PURE__ */ jsx4(
                  RadioGroup.Label,
                  {
                    as: "span",
                    className: joinClassnames(
                      checked ? "text-indigo-900" : "text-gray-900",
                      "block text-sm font-medium"
                    ),
                    children: option.name
                  }
                ),
                option.description && /* @__PURE__ */ jsx4(
                  RadioGroup.Description,
                  {
                    as: "span",
                    className: joinClassnames(
                      checked ? "text-indigo-700" : "text-gray-500",
                      "block text-sm"
                    ),
                    children: option.description
                  }
                )
              ] })
            ] })
          },
          option.name
        )) })
      ]
    }
  );
};

// src/Buttons/radioButtonsRow.tsx
import { jsx as jsx5 } from "react/jsx-runtime";
var RadioButtonsRow = ({
  optionsCount,
  rowIndex,
  selectionIndex,
  onChange,
  isReadOnly
}) => {
  const options = Array.from({ length: optionsCount }, (_, index) => index + 1);
  return /* @__PURE__ */ jsx5("fieldset", { children: /* @__PURE__ */ jsx5("div", { className: "flex items-center justify-between", children: options.map((option) => /* @__PURE__ */ jsx5(
    "div",
    {
      className: "flex-grow flex items-center justify-center",
      children: /* @__PURE__ */ jsx5(
        "label",
        {
          htmlFor: `option-${rowIndex}-${option}`,
          className: `flex-grow flex items-center justify-center ${isReadOnly ? "p-1" : "p-4"}`,
          children: /* @__PURE__ */ jsx5(
            "input",
            {
              id: `option-${rowIndex}-${option}`,
              type: "radio",
              checked: selectionIndex === option,
              onChange: () => onChange(option),
              disabled: Boolean(isReadOnly),
              className: "h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
            }
          )
        },
        `${rowIndex}-${option}`
      )
    },
    option
  )) }) });
};

// src/Cards/fileCard.tsx
import { DocumentIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { jsx as jsx6, jsxs as jsxs4 } from "react/jsx-runtime";
var FileCard = ({ file, onDelete }) => {
  return /* @__PURE__ */ jsx6("div", { className: "w-64 flex rounded-md shadow-sm", children: /* @__PURE__ */ jsxs4("div", { className: "flex flex-1 items-center justify-between truncate rounded-md border border-gray-200 bg-white", children: [
    /* @__PURE__ */ jsx6("div", { className: "px-2 py-2", children: /* @__PURE__ */ jsx6(DocumentIcon, { className: "h-3 w-3 text-gray-400", "aria-hidden": "true" }) }),
    /* @__PURE__ */ jsx6("div", { className: "flex-1 truncate py-2 text-sm font-medium text-gray-900 hover:text-gray-600", children: file.name }),
    /* @__PURE__ */ jsx6("div", { className: "flex-shrink-0", children: /* @__PURE__ */ jsxs4(
      "button",
      {
        type: "button",
        className: "inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
        onClick: onDelete,
        children: [
          /* @__PURE__ */ jsx6("span", { className: "sr-only", children: "Open options" }),
          /* @__PURE__ */ jsx6(XMarkIcon, { className: "h-5 w-5", "aria-hidden": "true" })
        ]
      }
    ) })
  ] }) });
};

// src/Checkbox/checkbox.tsx
import { useMemo } from "react";
import { jsx as jsx7, jsxs as jsxs5 } from "react/jsx-runtime";
var Checkbox = ({ isChecked, onChange, label }) => {
  const id = useMemo(
    () => `checkbox-${Math.random().toString(36).substr(2, 9)}`,
    []
  );
  return /* @__PURE__ */ jsxs5("label", { htmlFor: id, className: "flex items-center cursor-pointer", children: [
    /* @__PURE__ */ jsx7(
      "input",
      {
        id,
        className: "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 focus:border-indigo-500",
        type: "checkbox",
        checked: isChecked,
        onChange: ({ target: { checked } }) => onChange(checked)
      }
    ),
    label && /* @__PURE__ */ jsx7("span", { className: "ml-2 text-sm text-gray-800", children: label })
  ] });
};

// src/CounterButton/index.tsx
import { useState } from "react";
import { jsx as jsx8, jsxs as jsxs6 } from "react/jsx-runtime";
function CounterButton() {
  const [count, setCount] = useState(0);
  return /* @__PURE__ */ jsxs6(
    "div",
    {
      style: {
        background: `rgba(0,0,0,0.05)`,
        borderRadius: `8px`,
        padding: "1.5rem",
        fontWeight: 500
      },
      children: [
        /* @__PURE__ */ jsxs6("p", { style: { margin: "0 0 1.5rem 0" }, children: [
          "This component is from",
          " ",
          /* @__PURE__ */ jsx8(
            "code",
            {
              style: {
                padding: "0.2rem 0.3rem",
                background: `rgba(0,0,0,0.1)`,
                borderRadius: "0.25rem"
              },
              children: "ui"
            }
          )
        ] }),
        /* @__PURE__ */ jsx8("div", { children: /* @__PURE__ */ jsxs6(
          "button",
          {
            onClick: () => {
              setCount((c) => c + 1);
            },
            style: {
              background: "black",
              color: "white",
              border: "none",
              padding: "0.5rem 1rem",
              borderRadius: "0.25rem",
              display: "inline-block",
              cursor: "pointer"
            },
            type: "button",
            children: [
              "Count: ",
              count
            ]
          }
        ) })
      ]
    }
  );
}

// src/Dropdown/dropdownMenu.tsx
import { Menu, Transition } from "@headlessui/react";
import React from "react";
import { jsx as jsx9 } from "react/jsx-runtime";
var DropdownMenu = ({ items }) => {
  return /* @__PURE__ */ jsx9(
    Transition,
    {
      as: React.Fragment,
      enter: "transition ease-out duration-100",
      enterFrom: "transform opacity-0 scale-95",
      enterTo: "transform opacity-100 scale-100",
      leave: "transition ease-in duration-75",
      leaveFrom: "transform opacity-100 scale-100",
      leaveTo: "transform opacity-0 scale-95",
      children: /* @__PURE__ */ jsx9(Menu.Items, { className: "absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none", children: items.map((i) => {
        return /* @__PURE__ */ jsx9(Menu.Item, { children: ({ active }) => /* @__PURE__ */ jsx9(
          "a",
          {
            className: joinClassnames(
              active ? "bg-gray-100" : "",
              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
            ),
            onClick: i.onClick,
            children: i.label
          }
        ) }, "dropdown-item-" + i.label);
      }) })
    }
  );
};

// src/InfoBanner/infoBanner.tsx
import {
  ExclamationTriangleIcon,
  InformationCircleIcon as InformationCircleIcon2,
  RocketLaunchIcon
} from "@heroicons/react/24/outline";
import { jsx as jsx10, jsxs as jsxs7 } from "react/jsx-runtime";
var InfoBanner = ({
  title,
  description,
  icon,
  variant
}) => {
  const getVariantStyles = (variant2) => {
    switch (variant2) {
      case "WARNING" /* Warning */:
        return {
          bgColor: "bg-red-50",
          borderColor: "border-red-400",
          textColor: "text-red-800",
          subTextColor: "text-red-700",
          icon: /* @__PURE__ */ jsx10(
            ExclamationTriangleIcon,
            {
              className: "h-5 w-5 text-red-400",
              "aria-hidden": "true"
            }
          )
        };
      case "UPSELL":
        return {
          bgColor: "bg-violet-50",
          borderColor: "border-violet-400",
          textColor: "text-violet-800",
          subTextColor: "text-violet-700",
          icon: /* @__PURE__ */ jsx10(
            RocketLaunchIcon,
            {
              className: "h-5 w-5 text-violet-400",
              "aria-hidden": "true"
            }
          )
        };
      case "STANDARD" /* Standard */:
      default:
        return {
          bgColor: "bg-blue-50",
          borderColor: "border-blue-400",
          textColor: "text-blue-800",
          subTextColor: "text-blue-700",
          icon: /* @__PURE__ */ jsx10(
            InformationCircleIcon2,
            {
              className: "h-5 w-5 text-blue-400",
              "aria-hidden": "true"
            }
          )
        };
    }
  };
  const {
    bgColor,
    borderColor,
    textColor,
    subTextColor,
    icon: defaultIcon
  } = getVariantStyles(variant);
  return /* @__PURE__ */ jsx10("div", { className: `rounded-md ${bgColor} ${borderColor} border p-4`, children: /* @__PURE__ */ jsxs7("div", { className: "flex", children: [
    /* @__PURE__ */ jsx10("div", { className: "flex-shrink-0", children: icon || defaultIcon }),
    /* @__PURE__ */ jsxs7("div", { className: "ml-3", children: [
      /* @__PURE__ */ jsx10("h3", { className: `text-sm font-medium ${textColor}`, children: title }),
      description && /* @__PURE__ */ jsx10("div", { className: `mt-2 text-sm ${subTextColor}`, children: description })
    ] })
  ] }) });
};

// src/Inputs/fileUpload.tsx
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { useRef, useState as useState2 } from "react";
import { jsx as jsx11, jsxs as jsxs8 } from "react/jsx-runtime";
var MAX_FILE_SIZE = 10 * 1024 * 1024;
var IMAGE_FILE_TYPES = "image/jpeg,image/png";
var FileUpload = ({
  onUpload,
  isLoading,
  label,
  isDisabled
}) => {
  const [uploading, setUploading] = useState2(false);
  const [errorMessage, setErrorMessage] = useState2("");
  const fileInputRef = useRef(null);
  const handleFileChange = (event) => __async(void 0, null, function* () {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage("File size exceeds the 10MB limit.");
        return;
      }
      setUploading(true);
      setErrorMessage("");
      try {
        yield onUpload(file);
      } catch (error) {
        setErrorMessage("Failed to upload file.");
      } finally {
        setUploading(false);
      }
    }
  });
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return /* @__PURE__ */ jsxs8("div", { children: [
    /* @__PURE__ */ jsx11(
      Button,
      {
        variant: "SECONDARY" /* Secondary */,
        onClick: handleButtonClick,
        icon: /* @__PURE__ */ jsx11(ArrowUpTrayIcon, { className: "w-4 h-4 mr-2" }),
        label: label || "Upload",
        isLoading,
        isDisabled
      }
    ),
    /* @__PURE__ */ jsx11(
      "input",
      {
        id: "file-upload",
        name: "file-upload",
        type: "file",
        accept: IMAGE_FILE_TYPES,
        onChange: handleFileChange,
        disabled: uploading,
        className: "sr-only",
        ref: fileInputRef
      }
    ),
    errorMessage && /* @__PURE__ */ jsx11("p", { className: "text-sm text-red-500", children: errorMessage })
  ] });
};

// src/Inputs/input.tsx
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { forwardRef } from "react";
import { Fragment as Fragment4, jsx as jsx12, jsxs as jsxs9 } from "react/jsx-runtime";
var Input = forwardRef((props, ref) => {
  const {
    id,
    label,
    placeholder,
    description,
    errorDescription,
    value,
    onChange,
    onKeyPress,
    onKeyDown,
    onClickClearButton,
    leftIcon,
    inputClassName,
    onBlur
  } = props;
  const hasError = Boolean(errorDescription);
  const textColor = hasError ? "text-red-900" : "text-gray-900";
  const ringColor = hasError ? "ring-red-300" : "ring-gray-300";
  const placeholderColor = hasError ? "placeholder-red-400" : "placeholder-gray-400";
  const focusColor = hasError ? "focus:ring-red-500" : "focus:ring-indigo-500";
  const disabledClassNames = props.isDisabled ? `disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none` : "";
  const descriptionClassNames = `
    mt-2 text-sm ${hasError ? "text-red-600" : "text-gray-600"}
  `;
  const descriptionText = errorDescription || description;
  const renderRightIcon = () => {
    if (hasError) {
      return /* @__PURE__ */ jsx12("div", { className: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2", children: /* @__PURE__ */ jsx12(
        ExclamationCircleIcon,
        {
          "aria-hidden": "true",
          className: "h-5 w-5 text-red-500"
        }
      ) });
    }
    if (onClickClearButton && value) {
      return /* @__PURE__ */ jsx12("div", { className: "absolute inset-y-0 right-0 flex items-center pr-2", children: /* @__PURE__ */ jsx12(
        IconButton,
        {
          icon: /* @__PURE__ */ jsx12(XCircleIcon, { className: "h-4 w-4 text-gray-700" }),
          variant: "TERTIARY" /* Tertiary */,
          onClick: onClickClearButton
        }
      ) });
    }
    return null;
  };
  const rightIcon = renderRightIcon();
  const inputClassNames = `
    block w-full rounded-md border-0 py-1.5 ${textColor} shadow-sm ring-1 ring-inset ${ringColor} ${placeholderColor} focus:ring-2 focus:ring-inset ${focusColor} text-sm sm:leading-6 ${disabledClassNames} ${leftIcon ? "pl-8" : ""} ${rightIcon ? "pr-8" : ""} ${inputClassName || ""}
  `;
  return /* @__PURE__ */ jsxs9(Fragment4, { children: [
    label ? /* @__PURE__ */ jsx12("label", { className: "block text-sm font-medium leading-6 text-gray-900", children: label }) : null,
    /* @__PURE__ */ jsxs9(
      "div",
      {
        className: `relative ${label ? "mt-2" : ""} ${hasError ? "rounded-md shadow-sm" : ""}`,
        children: [
          leftIcon ? /* @__PURE__ */ jsx12("div", { className: "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2.5", children: leftIcon }) : null,
          /* @__PURE__ */ jsx12(
            "input",
            {
              "aria-describedby": `${id}-description`,
              "aria-invalid": hasError,
              autoComplete: props.autoComplete,
              className: inputClassNames,
              disabled: props.isDisabled,
              id,
              maxLength: props.maxLength || 100,
              minLength: props.minLength || 0,
              onChange,
              placeholder,
              onKeyPress,
              onKeyDown,
              onPaste: props.onPaste,
              onBlur,
              type: "text",
              value,
              autoFocus: props.autoFocus,
              ref
            }
          ),
          rightIcon
        ]
      }
    ),
    descriptionText ? /* @__PURE__ */ jsx12("p", { className: descriptionClassNames, id: `${id}-description`, children: descriptionText }) : null
  ] });
});

// src/Inputs/textarea.tsx
import { useEffect, useRef as useRef2 } from "react";

// src/Label/label.tsx
import { jsx as jsx13 } from "react/jsx-runtime";
var Label = ({ children, size, className }) => {
  const baseClasses2 = "font-semibold leading-6 text-gray-900 block flex flex-row items-center";
  return /* @__PURE__ */ jsx13(
    "label",
    {
      className: joinClassnames(baseClasses2, getSizeClasses(size), className),
      children
    }
  );
};
function getSizeClasses(size) {
  switch (size) {
    case "small" /* SMALL */:
      return "text-sm";
    case "medium" /* MEDIUM */:
      return "text-md";
    case "large" /* LARGE */:
      return "text-lg mb-2";
  }
}

// src/Inputs/textarea.tsx
import { Fragment as Fragment5, jsx as jsx14, jsxs as jsxs10 } from "react/jsx-runtime";
var TextareaModesEnum = /* @__PURE__ */ ((TextareaModesEnum2) => {
  TextareaModesEnum2["READ_AND_EDIT"] = "read_and_edit";
  TextareaModesEnum2["EDIT_ONLY"] = "edit_only";
  return TextareaModesEnum2;
})(TextareaModesEnum || {});
var Textarea = (props) => {
  const {
    id,
    placeholder,
    description,
    value,
    errorDescription,
    onChange,
    onPaste,
    onKeyDown,
    onBlur,
    isDisabled,
    autoFocus,
    shouldDisableNewline,
    label,
    maxLength,
    minLength,
    height,
    leftIcon,
    className
  } = props;
  const textareaRef = useRef2(null);
  const hasError = Boolean(errorDescription);
  const adjustHeight = () => {
    const textareaElement = textareaRef.current;
    if (textareaElement) {
      textareaElement.style.height = "auto";
      textareaElement.style.height = `${textareaElement.scrollHeight}px`;
    }
  };
  useEffect(() => {
    if (!height) {
      adjustHeight();
    }
  }, [value]);
  const textColor = hasError ? "text-red-900" : "text-gray-900";
  const ringColor = hasError ? "ring-red-300" : "ring-gray-300";
  const placeholderColor = hasError ? "placeholder-red-400" : "placeholder-gray-400";
  const wrapperFocusStyle = hasError ? "focus-within:ring-red-500" : "focus-within:ring-indigo-500";
  const textareaWrapperClassNames = `group whitespace-pre-wrap relative py-1.5 px-3 block 
    rounded-md border-0 text-sm ${textColor} shadow-sm ring-1 ring-inset
    ${ringColor} ${placeholderColor} focus-within:ring-inset focus-within:ring-2 ${wrapperFocusStyle} ${label ? "mt-2" : ""} ${leftIcon ? "pl-8" : ""} ${isDisabled ? "bg-slate-50 text-slate-500 border-slate-200 shadow-none" : "bg-white"} ${className}`;
  const descriptionClassNames = `
    mt-2 text-sm ${hasError ? "text-red-600" : "text-gray-600"}
  `;
  const descriptionText = errorDescription || description;
  const handleKeyDown = (event) => {
    if (shouldDisableNewline && event.key === "Enter") {
      event.preventDefault();
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };
  return /* @__PURE__ */ jsxs10(Fragment5, { children: [
    label ? /* @__PURE__ */ jsx14(Label, { size: "small" /* SMALL */, children: label }) : null,
    /* @__PURE__ */ jsxs10("div", { className: textareaWrapperClassNames, children: [
      leftIcon ? /* @__PURE__ */ jsx14("div", { className: "pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2.5", children: leftIcon }) : null,
      /* @__PURE__ */ jsx14(
        "textarea",
        {
          "aria-describedby": `${id}-description`,
          "aria-invalid": hasError,
          className: `p-0 leading-6 block w-full border-0 ${textColor} ${placeholderColor} text-sm focus:border-none focus:ring-0 resize-none ${isDisabled ? "bg-slate-50" : ""}`,
          id,
          maxLength: maxLength || 1e4,
          minLength: minLength || 0,
          onChange,
          placeholder,
          value,
          onPaste,
          onKeyDown: handleKeyDown,
          onBlur,
          ref: textareaRef,
          disabled: isDisabled,
          autoFocus,
          rows: 1,
          style: { height: height || "auto" }
        }
      )
    ] }),
    descriptionText ? /* @__PURE__ */ jsx14("p", { className: descriptionClassNames, id: `${id}-description`, children: descriptionText }) : null
  ] });
};

// src/Link/index.tsx
import { jsx as jsx15 } from "react/jsx-runtime";
function Link(_a) {
  var _b = _a, {
    children,
    href,
    sameTab
  } = _b, other = __objRest(_b, [
    "children",
    "href",
    "sameTab"
  ]);
  return /* @__PURE__ */ jsx15(
    "a",
    __spreadProps(__spreadValues({
      href,
      target: sameTab ? void 0 : "_blank"
    }, other), {
      className: "font-medium text-blue-600 hover:text-blue-500",
      children
    })
  );
}

// src/Loader/loader.tsx
import { zoomies } from "ldrs";
import { jsx as jsx16, jsxs as jsxs11 } from "react/jsx-runtime";
var LoaderStylesEnum = /* @__PURE__ */ ((LoaderStylesEnum2) => {
  LoaderStylesEnum2["SPINNER"] = "spinner";
  LoaderStylesEnum2["ZOOMIES"] = "zoomies";
  return LoaderStylesEnum2;
})(LoaderStylesEnum || {});
var Loader = (props) => {
  zoomies.register();
  switch (props.style) {
    case "spinner" /* SPINNER */:
      return /* @__PURE__ */ jsxs11(
        "svg",
        {
          "aria-hidden": "true",
          className: "w-4 h-4 mx-1 mb-0.5 inline text-gray-200 animate-spin dark:text-gray-200 fill-blue-600",
          viewBox: "0 0 100 101",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
          children: [
            /* @__PURE__ */ jsx16(
              "path",
              {
                d: "M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z",
                fill: "currentColor"
              }
            ),
            /* @__PURE__ */ jsx16(
              "path",
              {
                d: "M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z",
                fill: "currentFill"
              }
            )
          ]
        }
      );
    case "zoomies" /* ZOOMIES */:
      return /* @__PURE__ */ jsx16(
        "l-zoomies",
        {
          size: "100",
          stroke: "5",
          speed: "1.4",
          color: "#2563eb"
        }
      );
  }
};

// src/Loader/textCarouselLoader.tsx
import { useEffect as useEffect2, useState as useState3 } from "react";
import { Fragment as Fragment6, jsx as jsx17 } from "react/jsx-runtime";
var TextCarouselLoader = ({
  messages
}) => {
  const [loadingMessageIndex, setLoadingMessageIndex] = useState3(0);
  useEffect2(() => {
    const updateMessage = () => {
      if (loadingMessageIndex < messages.length - 1) {
        setLoadingMessageIndex(loadingMessageIndex + 1);
      }
    };
    if (loadingMessageIndex < messages.length - 1) {
      const randomInterval = Math.floor(Math.random() * 3e3) + 2e3;
      const timeoutId = setTimeout(updateMessage, randomInterval);
      return () => clearTimeout(timeoutId);
    }
  }, [loadingMessageIndex]);
  return /* @__PURE__ */ jsx17(Fragment6, { children: /* @__PURE__ */ jsx17(
    "div",
    {
      className: "font-semibold mb-4 text-sm animate-fadeIn",
      children: messages[loadingMessageIndex]
    },
    `loading-message-${loadingMessageIndex}`
  ) });
};

// src/Modal/modal.tsx
import { Dialog, Transition as Transition2 } from "@headlessui/react";
import { ExclamationTriangleIcon as ExclamationTriangleIcon2 } from "@heroicons/react/24/outline";

// ../app-types/src/client/client.ts
function noop() {
}

// ../app-types/src/constants/projectTypeToDefaultQuestions.ts
var winProjectSurveyQuestion = {
  question: "How important were each of the following factors in your decision to buy {{COMPANY_NAME}}?",
  scale: "important_unimportant" /* IMPORTANT_UNIMPORTANT */,
  type: "rating" /* RATING */,
  options: [
    {
      name: "Price \u2014 the value provided by {{COMPANY_NAME}} compared to the cost."
    },
    {
      name: "Product capabilities \u2014 the extent to which the {{COMPANY_NAME}} product met your needs."
    },
    {
      name: "Sales experience \u2014 the experience working with the {{COMPANY_NAME}} team during the purchase process."
    },
    {
      name: "Competition \u2014\xA0how {{COMPANY_NAME}} compared to other competitors you evaluated."
    }
  ]
};
var lossProjectSurveyQuestion = {
  question: "How important were each of the following factors in your decision not to continue with {{COMPANY_NAME}}?",
  type: "rating" /* RATING */,
  scale: "important_unimportant" /* IMPORTANT_UNIMPORTANT */,
  options: [
    {
      name: "Price \u2014 the cost of {{COMPANY_NAME}} relative to the value it provides."
    },
    {
      name: "Product capabilities \u2014 missing features or capabilities important to your requirements."
    },
    {
      name: "Sales experience \u2014 the experience working with the {{COMPANY_NAME}} team during the sales process."
    },
    {
      name: "Competition \u2014 alternatives to {{COMPANY_NAME}}, including your previous solution."
    }
  ]
};
var churnProjectSurveyQuestion = {
  question: "How important were each of the following factors in your decision to stop using {{COMPANY_NAME}}?",
  type: "rating" /* RATING */,
  scale: "important_unimportant" /* IMPORTANT_UNIMPORTANT */,
  options: [
    {
      name: "Price \u2014 the cost of {{COMPANY_NAME}} relative to the value it provides."
    },
    {
      name: "Product capabilities \u2014 missing features or capabilities important to your requirements."
    },
    {
      name: "Competition \u2014 alternatives to {{COMPANY_NAME}}, including your previous solution."
    },
    {
      name: "Business changes \u2014 changes to your business that resulted in you no longer getting value from {{COMPANY_NAME}}."
    }
  ]
};

// ../app-types/src/entities/company.ts
var DEFAULT_COMPANY_SETTINGS = Object.freeze({
  balance_refill_unit_amount: null,
  balance_refill_threshold_unit_amount: null,
  is_sending_invites_blocked: false,
  logo_file_name: null,
  interview_color: null,
  mode: null
});

// ../app-types/index.ts
var projectSettingsDefaults = {
  send_email_digest_to_all_teammates: true,
  email_digest_recipients: [],
  max_interview_count: null,
  is_slack_enabled: true,
  slack_channel_id: null,
  prompt_context: null,
  max_clarifying_questions_count: 2,
  redirect_url: null,
  welcome_message: null,
  interview_agent_type: "generic" /* GENERIC_INTERVIEWER */,
  is_incentive_charity_only: false
};

// src/Modal/modal.tsx
import { Fragment as Fragment7 } from "react";
import { Fragment as Fragment8, jsx as jsx18, jsxs as jsxs12 } from "react/jsx-runtime";
var SimpleModalVariantsEnum = /* @__PURE__ */ ((SimpleModalVariantsEnum2) => {
  SimpleModalVariantsEnum2["Standard"] = "STANDARD";
  SimpleModalVariantsEnum2["Warning"] = "WARNING";
  return SimpleModalVariantsEnum2;
})(SimpleModalVariantsEnum || {});
var Modal = (props) => {
  const { isOpen, onCancel, size } = props;
  return /* @__PURE__ */ jsx18(Transition2.Root, { show: isOpen, as: Fragment7, children: /* @__PURE__ */ jsxs12(Dialog, { as: "div", className: "relative z-10", onClose: onCancel || noop, children: [
    /* @__PURE__ */ jsx18(
      Transition2.Child,
      {
        as: Fragment7,
        enter: "ease-out duration-300",
        enterFrom: "opacity-0",
        enterTo: "opacity-100",
        leave: "ease-in duration-200",
        leaveFrom: "opacity-100",
        leaveTo: "opacity-0",
        children: /* @__PURE__ */ jsx18("div", { className: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" })
      }
    ),
    /* @__PURE__ */ jsx18("div", { className: "fixed inset-0 z-10 w-screen overflow-y-auto", children: /* @__PURE__ */ jsx18("div", { className: "flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0", children: /* @__PURE__ */ jsx18(
      Transition2.Child,
      {
        as: Fragment7,
        enter: "ease-out duration-300",
        enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
        enterTo: "opacity-100 translate-y-0 sm:scale-100",
        leave: "ease-in duration-200",
        leaveFrom: "opacity-100 translate-y-0 sm:scale-100",
        leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
        children: /* @__PURE__ */ jsx18(
          Dialog.Panel,
          {
            className: `relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full ${size === "large" /* LARGE */ ? "sm:max-w-4xl" : "sm:max-w-lg"} sm:p-6`,
            children: props.children
          }
        )
      }
    ) }) })
  ] }) });
};
var SimpleModal = (props) => {
  const {
    isOpen,
    variant,
    icon,
    title,
    subtitle,
    confirmButtonText,
    onCancel,
    onConfirm,
    isPrimaryButtonLoading,
    size
  } = props;
  const renderIcon = () => {
    if (variant === "WARNING" /* Warning */) {
      return /* @__PURE__ */ jsx18("div", { className: "mb-3 m:mt-5 mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100", children: /* @__PURE__ */ jsx18(
        ExclamationTriangleIcon2,
        {
          className: "h-6 w-6 text-red-600",
          "aria-hidden": "true"
        }
      ) });
    }
    return icon && /* @__PURE__ */ jsx18("div", { className: "mb-4", children: icon });
  };
  const renderPrimaryButton = () => {
    if (variant === "WARNING" /* Warning */)
      return /* @__PURE__ */ jsx18(
        Button,
        {
          variant: "WARNING" /* Warning */,
          label: confirmButtonText,
          onClick: onConfirm,
          isLoading: isPrimaryButtonLoading
        }
      );
    return /* @__PURE__ */ jsx18(
      Button,
      {
        variant: "PRIMARY" /* Primary */,
        label: confirmButtonText,
        onClick: onConfirm,
        isLoading: isPrimaryButtonLoading
      }
    );
  };
  const renderButtons = () => {
    if (onCancel)
      return /* @__PURE__ */ jsxs12("div", { className: "mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3", children: [
        /* @__PURE__ */ jsx18(
          Button,
          {
            variant: "SECONDARY" /* Secondary */,
            label: "Cancel",
            onClick: onCancel
          }
        ),
        renderPrimaryButton()
      ] });
    return /* @__PURE__ */ jsx18("div", { className: "mt-5 sm:mt-6 flex justify-center", children: renderPrimaryButton() });
  };
  return /* @__PURE__ */ jsx18(Modal, { isOpen, onCancel, size, children: /* @__PURE__ */ jsxs12(Fragment8, { children: [
    /* @__PURE__ */ jsxs12("div", { children: [
      renderIcon(),
      /* @__PURE__ */ jsxs12("div", { className: "text-center", children: [
        /* @__PURE__ */ jsx18(
          Dialog.Title,
          {
            as: "h3",
            className: "text-base font-semibold leading-6 text-gray-900",
            children: title
          }
        ),
        /* @__PURE__ */ jsx18("div", { className: "mt-2", children: /* @__PURE__ */ jsx18("p", { className: "text-sm text-gray-500", children: subtitle }) }),
        props.children
      ] })
    ] }),
    renderButtons()
  ] }) });
};

// src/Navigation/breadcrumbs.tsx
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { jsx as jsx19, jsxs as jsxs13 } from "react/jsx-runtime";
var Breadcrumbs = ({ items }) => {
  return /* @__PURE__ */ jsx19("nav", { "aria-label": "Breadcrumb", className: "hidden sm:flex", children: /* @__PURE__ */ jsx19("ol", { className: "flex items-center space-x-1.5", role: "list", children: items.map((item, index) => {
    if (index === 0)
      return /* @__PURE__ */ jsx19("li", { children: /* @__PURE__ */ jsx19("div", { className: "flex", children: /* @__PURE__ */ jsx19(
        Button,
        {
          label: item.label,
          onClick: item.onClick || noop,
          variant: "TERTIARY" /* Tertiary */
        }
      ) }) }, index);
    return /* @__PURE__ */ jsx19("li", { children: /* @__PURE__ */ jsxs13("div", { className: "flex items-center", children: [
      /* @__PURE__ */ jsx19(
        ChevronRightIcon,
        {
          "aria-hidden": "true",
          className: "h-5 w-5 flex-shrink-0 text-gray-400 mr-1.5"
        }
      ),
      /* @__PURE__ */ jsx19(
        Button,
        {
          label: item.label,
          onClick: item.onClick || noop,
          variant: "TERTIARY" /* Tertiary */,
          isDisabled: index === items.length - 1
        }
      )
    ] }) }, index);
  }) }) });
};

// src/Navigation/smallTabs.tsx
import { jsx as jsx20, jsxs as jsxs14 } from "react/jsx-runtime";
var SmallTabs = ({
  tabs,
  selectedTabIndex,
  onSelectTabIndex
}) => {
  return /* @__PURE__ */ jsx20(
    "nav",
    {
      className: "isolate inline-flex divide-x divide-gray-200 rounded-lg shadow whitespace-nowrap",
      "aria-label": "Tabs",
      children: tabs.map((tab, tabIdx) => {
        return /* @__PURE__ */ jsxs14(
          "a",
          {
            className: joinClassnames(
              selectedTabIndex === tabIdx ? "text-gray-900" : "text-gray-500 hover:text-gray-700",
              tabIdx === 0 ? "rounded-l-lg" : "",
              tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
              "group relative min-w-0 overflow-hidden bg-white py-2 px-3 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 cursor-pointer"
            ),
            onClick: () => onSelectTabIndex(tabIdx),
            children: [
              /* @__PURE__ */ jsx20("span", { children: tab }),
              /* @__PURE__ */ jsx20(
                "span",
                {
                  "aria-hidden": "true",
                  className: joinClassnames(
                    selectedTabIndex === tabIdx ? "bg-indigo-500" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )
                }
              )
            ]
          },
          tab
        );
      })
    }
  );
};

// src/Navigation/tabs.tsx
import { jsx as jsx21 } from "react/jsx-runtime";
var Tabs = ({ items, currentTab, onSelectTab }) => {
  return /* @__PURE__ */ jsx21("nav", { className: "-mb-px flex space-x-8", children: items.map((tab) => /* @__PURE__ */ jsx21(
    "button",
    {
      className: joinClassnames(
        tab === currentTab ? "border-indigo-500 text-indigo-600" : "border-transparent text-gray-700 hover:border-gray-500 hover:text-gray-900",
        "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-semibold"
      ),
      onClick: () => {
        onSelectTab(tab);
      },
      disabled: tab === currentTab,
      children: tab
    },
    tab
  )) });
};

// src/Pills/pill.tsx
import { jsx as jsx22 } from "react/jsx-runtime";
var PillColorsEnum = /* @__PURE__ */ ((PillColorsEnum2) => {
  PillColorsEnum2["BLUE"] = "blue";
  PillColorsEnum2["GREEN"] = "green";
  PillColorsEnum2["RED"] = "red";
  PillColorsEnum2["YELLOW"] = "yellow";
  PillColorsEnum2["GREY"] = "grey";
  PillColorsEnum2["LIME"] = "lime";
  return PillColorsEnum2;
})(PillColorsEnum || {});
var Pill = ({ label, size, color, onClick }) => {
  const sharedClasses = "inline-flex items-center rounded-md font-medium ring-1 ring-inset h-fit cursor-default";
  const generateSizeStyle = (size2) => {
    switch (size2) {
      case "small" /* SMALL */:
        return "px-2 py-1 text-xs";
      case "medium" /* MEDIUM */:
        return "px-3 py-2 text-sm";
      case "large" /* LARGE */:
        return "px-5 py-2 text-lg";
    }
  };
  const generateColorStyle = (color2) => {
    switch (color2) {
      case "blue" /* BLUE */:
        return `bg-blue-50 text-blue-700 ring-blue-600/20 border-blue-600 ${onClick ? "hover:bg-blue-100 cursor-pointer" : ""}`;
      case "green" /* GREEN */:
        return `bg-green-50 text-green-700 ring-green-600/20 ${onClick ? "hover:bg-green-100 cursor-pointer" : ""}`;
      case "red" /* RED */:
        return `bg-red-50 text-red-700 ring-red-600/20 ${onClick ? "hover:bg-red-100 cursor-pointer" : ""}`;
      case "yellow" /* YELLOW */:
        return `bg-yellow-50 text-yellow-700 ring-yellow-600/20 ${onClick ? "hover:bg-yellow-100 cursor-pointer" : ""}`;
      case "grey" /* GREY */:
        return `bg-gray-50 text-gray-700 ring-gray-600/20 ${onClick ? "hover:bg-grey-100 cursor-pointer" : ""}`;
      case "lime" /* LIME */:
        return `bg-lime-50 text-lime-700 ring-lime-600/20 ${onClick ? "hover:bg-lime-100 cursor-pointer" : ""}`;
    }
  };
  return /* @__PURE__ */ jsx22(
    "div",
    {
      className: joinClassnames(
        sharedClasses,
        generateSizeStyle(size),
        generateColorStyle(color)
      ),
      onClick,
      children: label
    }
  );
};

// src/SearchableSelect/searchableSelect.tsx
import { Combobox } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon as XMarkIcon2
} from "@heroicons/react/20/solid";
import { useMemo as useMemo2, useState as useState4 } from "react";
import { Fragment as Fragment9, jsx as jsx23, jsxs as jsxs15 } from "react/jsx-runtime";
var SearchableSelectModesEnum = /* @__PURE__ */ ((SearchableSelectModesEnum2) => {
  SearchableSelectModesEnum2["SINGLE"] = "single";
  SearchableSelectModesEnum2["MULTI"] = "multi";
  return SearchableSelectModesEnum2;
})(SearchableSelectModesEnum || {});
var SearchableSelect = (props) => {
  const {
    mode,
    placeholder,
    label,
    onChange,
    options,
    description,
    errorDescription,
    id,
    shouldDisableCustomOptions
  } = props;
  const selection = mode === "single" /* SINGLE */ ? props.selection : null;
  const selectedOptions = mode === "multi" /* MULTI */ ? props.selectedOptions : [];
  const [query, setQuery] = useState4("");
  const filteredOptions = useMemo2(() => {
    const lowerQuery = query.toLowerCase().replace(/\s+/g, "");
    if (!query)
      return options;
    return options.filter((option) => {
      const optionAlreadySelected = mode === "multi" /* MULTI */ && selectedOptions.some((o) => option.id === o.id) || mode === "single" /* SINGLE */ && selection === option;
      return !optionAlreadySelected && option.name.toLowerCase().replace(/\s+/g, "").includes(lowerQuery);
    });
  }, [options, selection, selectedOptions, query]);
  const hasError = Boolean(errorDescription);
  const removeOption = (option) => {
    if (mode === "multi" /* MULTI */)
      onChange(selectedOptions.filter((o) => o.id !== option.id));
  };
  const handleBackspace = (event) => {
    if (event.key === "Backspace" && query === "" && mode === "multi" /* MULTI */ && selectedOptions.length > 0) {
      return onChange(selectedOptions.slice(0, -1));
    }
  };
  const handleOnChange = (value) => {
    onChange(value);
    setQuery("");
  };
  const descriptionClassNames = `
  mt-2 text-sm ${hasError ? "text-red-600" : "text-gray-600"}
`;
  const descriptionText = errorDescription || description;
  const renderComboboxContent = () => {
    return /* @__PURE__ */ jsxs15("div", { className: "relative mt-1", children: [
      /* @__PURE__ */ jsxs15("div", { className: "relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm", children: [
        /* @__PURE__ */ jsxs15("div", { className: "flex flex-wrap items-center gap-2 p-2 pr-5", children: [
          mode === "multi" /* MULTI */ && selectedOptions.map((option) => /* @__PURE__ */ jsxs15(
            "span",
            {
              className: "flex items-center gap-1 rounded bg-gray-200 px-2 py-1 text-gray-800",
              children: [
                option.name,
                /* @__PURE__ */ jsx23("button", { onClick: () => removeOption(option), children: /* @__PURE__ */ jsx23(XMarkIcon2, { className: "h-4 w-4", "aria-hidden": "true" }) })
              ]
            },
            option.id
          )),
          mode === "multi" /* MULTI */ ? /* @__PURE__ */ jsx23(
            Combobox.Input,
            {
              className: "flex-1 border-none py-1 pl-1 text-sm leading-5 text-gray-900 focus:ring-0",
              onChange: (event) => setQuery(event.target.value),
              onKeyDown: handleBackspace,
              placeholder,
              value: query,
              autoComplete: "off"
            }
          ) : /* @__PURE__ */ jsx23(
            Combobox.Input,
            {
              className: "flex-1 border-none py-1 pl-1 text-sm leading-5 text-gray-900 focus:ring-0",
              onChange: (event) => setQuery(event.target.value),
              placeholder,
              displayValue: (value) => {
                if (value)
                  return value.name;
                return "";
              },
              autoComplete: "off"
            }
          )
        ] }),
        /* @__PURE__ */ jsx23(Combobox.Button, { className: "absolute inset-y-0 right-0 flex items-center pr-2", children: /* @__PURE__ */ jsx23(
          ChevronUpDownIcon,
          {
            className: "h-5 w-5 text-gray-400",
            "aria-hidden": "true"
          }
        ) })
      ] }),
      /* @__PURE__ */ jsxs15(
        Combobox.Options,
        {
          className: `${query.length === 0 && filteredOptions.length == 0 ? "hidden" : ""} absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm`,
          children: [
            !shouldDisableCustomOptions && query.length > 0 && /* @__PURE__ */ jsx23(
              Combobox.Option,
              {
                value: { id: query, name: query },
                className: ({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-gray-100 text-gray-900" : "text-gray-900"}`,
                children: ({ selected, active }) => /* @__PURE__ */ jsxs15(
                  "span",
                  {
                    className: `block truncate ${selected ? "font-medium" : "font-normal"}`,
                    children: [
                      'Add "',
                      query,
                      '"'
                    ]
                  }
                )
              }
            ),
            shouldDisableCustomOptions && filteredOptions.length === 0 && query.length > 0 && /* @__PURE__ */ jsx23("div", { className: "py-2 pl-4 pr-4", children: "No matches found" }),
            filteredOptions.map((option) => {
              return /* @__PURE__ */ jsx23(
                Combobox.Option,
                {
                  value: option,
                  className: ({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-gray-100 text-gray-900" : "text-gray-900"}`,
                  children: ({ active, selected }) => {
                    return /* @__PURE__ */ jsxs15(Fragment9, { children: [
                      /* @__PURE__ */ jsx23(
                        "span",
                        {
                          className: `block truncate ${selected ? "font-medium" : "font-normal"}`,
                          children: option.name
                        }
                      ),
                      selected ? /* @__PURE__ */ jsx23(
                        "span",
                        {
                          className: `absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-gray-900" : "text-blue-600"}`,
                          children: /* @__PURE__ */ jsx23(CheckIcon, { className: "h-5 w-5", "aria-hidden": "true" })
                        }
                      ) : null
                    ] });
                  }
                },
                option.id
              );
            })
          ]
        }
      )
    ] });
  };
  return /* @__PURE__ */ jsxs15(Fragment9, { children: [
    label ? /* @__PURE__ */ jsx23("label", { className: "block text-sm font-medium leading-6 text-gray-900", children: label }) : null,
    mode === "single" /* SINGLE */ ? /* @__PURE__ */ jsx23(Combobox, { value: selection, onChange: handleOnChange, by: "id", children: renderComboboxContent() }) : /* @__PURE__ */ jsx23(
      Combobox,
      {
        value: selectedOptions,
        onChange: handleOnChange,
        by: "id",
        multiple: true,
        children: renderComboboxContent()
      }
    ),
    descriptionText ? /* @__PURE__ */ jsx23("p", { className: descriptionClassNames, id: `${id}-description`, children: descriptionText }) : null
  ] });
};

// src/Select/select.tsx
import { Listbox, Transition as Transition3 } from "@headlessui/react";
import { CheckIcon as CheckIcon2, ChevronUpDownIcon as ChevronUpDownIcon2 } from "@heroicons/react/20/solid";
import * as React3 from "react";
import { Fragment as Fragment11, jsx as jsx24, jsxs as jsxs16 } from "react/jsx-runtime";
var Select = (props) => {
  const { options, onChange, currentSelection, label, placeholder } = props;
  const buttonRef = React3.useRef(null);
  const [shouldOpenUpwards, setShouldOpenUpwards] = React3.useState(false);
  const handleButtonClick = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceNeeded = 200;
      setShouldOpenUpwards(spaceBelow < spaceNeeded);
    }
    if (props.onClick) {
      props.onClick();
    }
  };
  return /* @__PURE__ */ jsx24(Listbox, { onChange, value: currentSelection, children: ({ open }) => /* @__PURE__ */ jsxs16("div", { className: "relative", children: [
    label && /* @__PURE__ */ jsx24(
      "label",
      {
        htmlFor: "location",
        className: "block text-sm font-medium leading-6 text-gray-900 mb-2",
        children: label
      }
    ),
    /* @__PURE__ */ jsxs16(
      Listbox.Button,
      {
        ref: buttonRef,
        className: "relative w-full cursor-default min-h-[36px] min-w-[100px] rounded-md bg-white py-1.5 pl-3 pr-7 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6",
        onClick: handleButtonClick,
        children: [
          currentSelection && /* @__PURE__ */ jsx24("span", { className: "block truncate", children: currentSelection.name }),
          !currentSelection && placeholder && /* @__PURE__ */ jsx24("span", { className: "text-gray-400", children: placeholder }),
          /* @__PURE__ */ jsx24("span", { className: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2", children: /* @__PURE__ */ jsx24(
            ChevronUpDownIcon2,
            {
              "aria-hidden": "true",
              className: "h-5 w-5 text-gray-400"
            }
          ) })
        ]
      }
    ),
    /* @__PURE__ */ jsx24(
      Transition3,
      {
        as: React3.Fragment,
        leave: "transition ease-in duration-100",
        leaveFrom: "opacity-100",
        leaveTo: "opacity-0",
        show: open,
        children: /* @__PURE__ */ jsx24(
          Listbox.Options,
          {
            className: joinClassnames(
              "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
              shouldOpenUpwards ? "bottom-full" : "top-full"
            ),
            children: options.map((option, key) => /* @__PURE__ */ jsx24(
              Listbox.Option,
              {
                className: ({ active }) => joinClassnames(
                  active ? "bg-indigo-600 text-white" : "text-gray-900",
                  "relative cursor-default select-none py-2 pl-3 pr-9"
                ),
                value: option,
                children: ({ selected, active }) => /* @__PURE__ */ jsxs16(Fragment11, { children: [
                  /* @__PURE__ */ jsx24(
                    "span",
                    {
                      className: joinClassnames(
                        selected ? "font-semibold" : "font-normal",
                        "block truncate"
                      ),
                      children: option.name
                    }
                  ),
                  selected ? /* @__PURE__ */ jsx24(
                    "span",
                    {
                      className: joinClassnames(
                        active ? "text-white" : "text-indigo-600",
                        "absolute inset-y-0 right-0 flex items-center pr-4"
                      ),
                      children: /* @__PURE__ */ jsx24(CheckIcon2, { "aria-hidden": "true", className: "h-5 w-5" })
                    }
                  ) : null
                ] })
              },
              key
            ))
          }
        )
      }
    )
  ] }) });
};

// src/Skeletons/skeletons.tsx
import { jsx as jsx25, jsxs as jsxs17 } from "react/jsx-runtime";
var TableSkeleton = () => {
  return /* @__PURE__ */ jsx25("tr", { children: /* @__PURE__ */ jsx25("td", { colSpan: 100, children: /* @__PURE__ */ jsxs17(
    "div",
    {
      role: "status",
      className: "p-4 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse",
      children: [
        /* @__PURE__ */ jsxs17("div", { className: "flex items-center justify-between", children: [
          /* @__PURE__ */ jsxs17("div", { children: [
            /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24 mb-2.5" }),
            /* @__PURE__ */ jsx25("div", { className: "w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-400" })
          ] }),
          /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-12" })
        ] }),
        /* @__PURE__ */ jsxs17("div", { className: "flex items-center justify-between pt-4", children: [
          /* @__PURE__ */ jsxs17("div", { children: [
            /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24 mb-2.5" }),
            /* @__PURE__ */ jsx25("div", { className: "w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-400" })
          ] }),
          /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-12" })
        ] }),
        /* @__PURE__ */ jsxs17("div", { className: "flex items-center justify-between pt-4", children: [
          /* @__PURE__ */ jsxs17("div", { children: [
            /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24 mb-2.5" }),
            /* @__PURE__ */ jsx25("div", { className: "w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-400" })
          ] }),
          /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-12" })
        ] }),
        /* @__PURE__ */ jsx25("span", { className: "sr-only", children: "Loading..." })
      ]
    }
  ) }) });
};
var DefaultSkeleton = () => {
  return /* @__PURE__ */ jsxs17("div", { role: "status", className: "max-w-sm animate-pulse", children: [
    /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-48 mb-4" }),
    /* @__PURE__ */ jsx25("div", { className: "h-2 bg-gray-200 rounded-full dark:bg-gray-400 max-w-[360px] mb-2.5" }),
    /* @__PURE__ */ jsx25("div", { className: "h-2 bg-gray-200 rounded-full dark:bg-gray-400 mb-2.5" }),
    /* @__PURE__ */ jsx25("div", { className: "h-2 bg-gray-200 rounded-full dark:bg-gray-400 max-w-[330px] mb-2.5" }),
    /* @__PURE__ */ jsx25("div", { className: "h-2 bg-gray-200 rounded-full dark:bg-gray-400 max-w-[300px] mb-2.5" }),
    /* @__PURE__ */ jsx25("div", { className: "h-2 bg-gray-200 rounded-full dark:bg-gray-400 max-w-[360px]" }),
    /* @__PURE__ */ jsx25("span", { className: "sr-only", children: "Loading..." })
  ] });
};
var TextSkeleton = () => {
  return /* @__PURE__ */ jsxs17("div", { role: "status", className: "space-y-2.5 animate-pulse max-w-lg", children: [
    /* @__PURE__ */ jsxs17("div", { className: "flex items-center w-full space-x-2", children: [
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-32" }),
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24" }),
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-full" })
    ] }),
    /* @__PURE__ */ jsxs17("div", { className: "flex items-center w-full space-x-2 max-w-[480px]", children: [
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-full" }),
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-full" }),
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24" })
    ] }),
    /* @__PURE__ */ jsxs17("div", { className: "flex items-center w-full space-x-2 max-w-[400px]", children: [
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-full" }),
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-80" }),
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-full" })
    ] }),
    /* @__PURE__ */ jsxs17("div", { className: "flex items-center w-full space-x-2 max-w-[480px]", children: [
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-full" }),
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-full" }),
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24" })
    ] }),
    /* @__PURE__ */ jsxs17("div", { className: "flex items-center w-full space-x-2 max-w-[440px]", children: [
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-32" }),
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-24" }),
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-full" })
    ] }),
    /* @__PURE__ */ jsxs17("div", { className: "flex items-center w-full space-x-2 max-w-[360px]", children: [
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-full" }),
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-200 rounded-full dark:bg-gray-400 w-80" }),
      /* @__PURE__ */ jsx25("div", { className: "h-2.5 bg-gray-300 rounded-full dark:bg-gray-400 w-full" })
    ] }),
    /* @__PURE__ */ jsx25("span", { className: "sr-only", children: "Loading..." })
  ] });
};
var FullPageSkeleton = () => {
  return /* @__PURE__ */ jsxs17("div", { className: "mx-auto max-w-4xl p-6 space-y-11", children: [
    /* @__PURE__ */ jsx25(DefaultSkeleton, {}),
    /* @__PURE__ */ jsx25(TextSkeleton, {})
  ] });
};

// src/Slideover/slideover.tsx
import { Dialog as Dialog2, Transition as Transition4 } from "@headlessui/react";
import { XMarkIcon as XMarkIcon3 } from "@heroicons/react/24/outline";
import { Fragment as Fragment12 } from "react";
import { jsx as jsx26, jsxs as jsxs18 } from "react/jsx-runtime";
var Slideover = ({
  onClickClose,
  title,
  children,
  buttons,
  shouldShow
}) => {
  return /* @__PURE__ */ jsx26(Transition4.Root, { show: shouldShow, as: Fragment12, children: /* @__PURE__ */ jsx26(Dialog2, { as: "div", className: "relative z-10", onClose: onClickClose, children: /* @__PURE__ */ jsx26("div", { className: "fixed inset-x-0 top-[50px] bottom-0 overflow-hidden", children: /* @__PURE__ */ jsx26("div", { className: "absolute inset-0 overflow-hidden", children: /* @__PURE__ */ jsx26("div", { className: "pointer-events-none fixed bottom-0 top-[50px] right-0 flex max-w-full", children: /* @__PURE__ */ jsx26(
    Transition4.Child,
    {
      as: Fragment12,
      enter: "transform transition ease-in-out duration-150 sm:duration-150",
      enterFrom: "translate-x-full",
      enterTo: "translate-x-0",
      leave: "transform transition ease-in-out duration-150 sm:duration-150",
      leaveFrom: "translate-x-0",
      leaveTo: "translate-x-full",
      children: /* @__PURE__ */ jsx26(Dialog2.Panel, { className: "pointer-events-auto w-screen max-w-5xl", children: /* @__PURE__ */ jsxs18(
        "div",
        {
          className: "grid h-full bg-white shadow-xl",
          style: {
            gridTemplateRows: buttons ? "60px 1fr 60px" : "60px 1fr"
          },
          children: [
            /* @__PURE__ */ jsx26("div", { className: "bg-gray-50 px-4 py-4 sm:px-6 border-b border-gray-200", children: /* @__PURE__ */ jsxs18("div", { className: "flex items-start justify-between space-x-3", children: [
              /* @__PURE__ */ jsx26(Dialog2.Title, { className: "text-base font-semibold leading-6 text-gray-900", children: title }),
              /* @__PURE__ */ jsx26("div", { className: "flex h-7 items-center", children: /* @__PURE__ */ jsxs18(
                "button",
                {
                  type: "button",
                  className: "relative text-gray-400 hover:text-gray-500",
                  onClick: onClickClose,
                  children: [
                    /* @__PURE__ */ jsx26("span", { className: "absolute -inset-2.5" }),
                    /* @__PURE__ */ jsx26("span", { className: "sr-only", children: "Close panel" }),
                    /* @__PURE__ */ jsx26(XMarkIcon3, { className: "h-6 w-6", "aria-hidden": "true" })
                  ]
                }
              ) })
            ] }) }),
            /* @__PURE__ */ jsx26("div", { className: "flex flex-col overflow-y-scroll h-full", children }),
            buttons && /* @__PURE__ */ jsx26("div", { className: "flex justify-end space-x-3 px-4 py-3 border-t border-gray-200 bg-white", children: buttons })
          ]
        }
      ) })
    }
  ) }) }) }) }) });
};

// src/Tables/surveyQuestionTable.tsx
import { jsx as jsx27, jsxs as jsxs19 } from "react/jsx-runtime";
var focusStyle = "focus:outline focus:outline-1 focus:outline-indigo-600";
var SurveyQuestionTable = ({
  columnNames,
  rowNames,
  rowRefs,
  onChange,
  isReadOnly,
  currentRatings
}) => {
  return /* @__PURE__ */ jsxs19("table", { className: "divide-y divide-gray-300 w-full", children: [
    /* @__PURE__ */ jsx27("thead", { children: /* @__PURE__ */ jsxs19("tr", { children: [
      /* @__PURE__ */ jsx27("th", {}),
      columnNames.map((name, index) => /* @__PURE__ */ jsx27(
        "th",
        {
          className: `text-xs text-gray-900 pb-2 w-[12%] ${isReadOnly ? "font-medium" : "font-semibold"}`,
          children: name
        },
        index
      ))
    ] }) }),
    /* @__PURE__ */ jsx27("tbody", { className: "divide-y divide-gray-200", children: rowNames.map((rowName, rowIndex) => {
      var _a;
      return /* @__PURE__ */ jsxs19("tr", { children: [
        /* @__PURE__ */ jsx27(
          "td",
          {
            className: `text-sm text-gray-900 ${isReadOnly ? "py-1" : "py-2 font-medium"}`,
            children: rowName
          }
        ),
        /* @__PURE__ */ jsx27(
          "td",
          {
            colSpan: columnNames.length,
            tabIndex: isReadOnly ? void 0 : rowIndex,
            ref: rowRefs ? rowRefs[rowIndex] : void 0,
            className: `${isReadOnly ? "" : focusStyle}`,
            children: /* @__PURE__ */ jsx27(
              RadioButtonsRow,
              {
                optionsCount: columnNames.length,
                rowIndex,
                selectionIndex: (_a = currentRatings.find((rating) => rating.name === rowName)) == null ? void 0 : _a.rating,
                onChange: (index) => {
                  if (onChange)
                    onChange(rowName, index);
                },
                isReadOnly
              }
            )
          }
        )
      ] }, rowIndex);
    }) })
  ] });
};
export {
  Breadcrumbs,
  Button,
  ButtonVariantsEnum,
  Checkbox,
  CounterButton,
  DefaultSkeleton,
  DropdownMenu,
  FileCard,
  FileUpload,
  FullPageSkeleton,
  IconButton,
  InfoBanner,
  InfoTooltip,
  Input,
  Label,
  Link,
  Loader,
  LoaderStylesEnum,
  Modal,
  SimpleModalVariantsEnum as ModalVariantsEnum,
  Pill,
  PillColorsEnum,
  RadioButtonsGroup,
  RadioButtonsRow,
  SearchableSelect,
  SearchableSelectModesEnum,
  Select,
  SimpleModal,
  SizesEnum,
  Slideover,
  SmallTabs,
  SurveyQuestionTable,
  TableSkeleton,
  Tabs,
  TextCarouselLoader,
  TextSkeleton,
  Textarea,
  TextareaModesEnum,
  capitalize,
  getIconButtonClassNames,
  getInitials,
  getInterviewLinkForProject,
  isValidName,
  joinClassnames,
  toHumanReadableString
};
