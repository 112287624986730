import { Tables, TablesInsert, TablesUpdate } from "../db/supabase.ts";
import { BillingPlansEnum } from "../helpers/billingFeatureGating.ts";

export type CompanyDB = Tables<"company">;
export type CompanyInsertDB = TablesInsert<"company">;
export type CompanyUpdateDB = Omit<
  TablesUpdate<"company">,
  | "id"
  | "created_at"
  | "description"
  | "billing_company_id"
  | "billing_current_plan"
  | "has_sensitive_data"
> & {
  description?: string;
  billing_company_id?: string;
  billing_current_plan?: BillingPlansEnum | null;
  settings?: CompanySettings;
};

export enum CompanyModesEnum {
  RECRUITING = "recruiting",
}

export interface CompanySettings {
  balance_refill_unit_amount: number | null;
  balance_refill_threshold_unit_amount: number | null;
  is_sending_invites_blocked: boolean;
  logo_file_name: string | null;
  interview_color: string | null;
  mode: CompanyModesEnum | null;
}

export interface Company {
  id: string;
  name: string;
  description: string | null;
  billing_company_id: string | null;
  billing_current_plan: BillingPlansEnum | null;
  billing_usage_fee_percent: number;
  has_sensitive_data: boolean;
  created_at: string;
  updated_at: string | null;
  trial_expires_at: string | null;
  settings: CompanySettings;
}

const DEFAULT_USAGE_FEE_PERCENT = 30;

// How we fill properties that an existing company does not have
const DEFAULT_COMPANY_SETTINGS: CompanySettings = Object.freeze({
  balance_refill_unit_amount: null,
  balance_refill_threshold_unit_amount: null,
  is_sending_invites_blocked: false,
  logo_file_name: null,
  interview_color: null,
  mode: null,
});

export function mapCompanyDBToCompany(c: CompanyDB) {
  const company: Company = {
    id: c.id,
    name: c.name,
    description: c.description,
    billing_company_id: c.billing_company_id,
    billing_current_plan: c.billing_current_plan
      ? (c.billing_current_plan as BillingPlansEnum)
      : null,
    billing_usage_fee_percent:
      typeof c.billing_usage_fee_percent === "number"
        ? c.billing_usage_fee_percent
        : DEFAULT_USAGE_FEE_PERCENT,
    has_sensitive_data: Boolean(c.has_sensitive_data),
    settings: {
      ...DEFAULT_COMPANY_SETTINGS,
      ...(typeof c.settings === "object" ? c.settings : {}),
    },
    created_at: c.created_at,
    updated_at: c.updated_at,
    trial_expires_at: c.trial_expires_at,
  };

  return company;
}

export type CompanyDomainDB = Tables<"company_domain">;

export interface CompanyDomain {
  domain: string;
  company_id: string;
}

export function mapCompanyDomainDBToCompanyDomain(c: CompanyDomainDB) {
  return {
    domain: c.domain,
    company_id: c.company_id,
  };
}
