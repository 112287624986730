import * as React from "react";
import {
  BasicContact,
  ComplexQuestion,
  ComplexQuestionInsert,
  InterviewAgentTypesEnum,
  InterviewStatusEnum,
  LineChartDataSegment,
  ProjectModesEnum,
  TranscriptFragmentRolesEnum,
} from "../..";

/*
 * Utilities.
 */

export type Handler = React.MouseEventHandler<HTMLElement>;

export type HandlerOf<T> = (data: T) => void;

export function noop() {}

/*
 * Enums.
 */

export enum IncentiveProviderType {
  Amazon = "AMAZON",
}

export enum ActivityTypesEnum {
  EMAIL_SENT = "email_sent",
  EMAIL_BOUNCED = "email_bounced",
  EMAIL_MARKED_SPAM = "email_marked_spam",
  IN_PROGRESS_INTERVIEW_REMINDER_EMAIL = "in_progress_interview_reminder_email",
}

export enum SurveyScalesEnum {
  IMPORTANT_UNIMPORTANT = "important_unimportant",
  AGREE_DISAGREE = "agree_disagree",
  SATISFIED_DISSATISFIED = "satisfied_dissatisfied",
}

export const SurveyScaleToRatings = {
  [SurveyScalesEnum.IMPORTANT_UNIMPORTANT]: [
    {
      name: "Not at all important",
      rating: 1,
    },
    {
      name: "Not so important",
      rating: 2,
    },
    {
      name: "Somewhat important",
      rating: 3,
    },
    {
      name: "Very important",
      rating: 4,
    },
    {
      name: "Extremely important",
      rating: 5,
    },
  ],
  [SurveyScalesEnum.AGREE_DISAGREE]: [
    {
      name: "Strongly disagree",
      rating: 1,
    },
    {
      name: "Disagree",
      rating: 2,
    },
    {
      name: "Neutral",
      rating: 3,
    },
    {
      name: "Agree",
      rating: 4,
    },
    {
      name: "Strongly agree",
      rating: 5,
    },
  ],
  [SurveyScalesEnum.SATISFIED_DISSATISFIED]: [
    {
      name: "Very dissatisfied",
      rating: 1,
    },
    {
      name: "Dissatisfied",
      rating: 2,
    },
    {
      name: "Neutral",
      rating: 3,
    },
    {
      name: "Satisfied",
      rating: 4,
    },
    {
      name: "Very satisfied",
      rating: 5,
    },
  ],
};

export enum ComplexQuestionTypesEnum {
  RATING = "rating",
  MULTIPLE_CHOICE = "multiple_choice",
}

export const ComplexQuestionTypeToString = {
  [ComplexQuestionTypesEnum.RATING]: "Rating scale",
  [ComplexQuestionTypesEnum.MULTIPLE_CHOICE]: "Multiple choice",
};

export type SurveyInsights = MatrixQuestionInsights | MultipleChoiceInsights;

export type MatrixQuestionInsights = {
  question: string;
  scale: SurveyScalesEnum;
  response_count: number;
  type: ComplexQuestionTypesEnum.RATING;
  data: RatingSurveyDataPoint[];
};

export type RatingSurveyDataPoint = {
  name: string;
  // The key is the rating, the value is the count of that rating
  // e.g. 1: 12 means 12 people rated this as 1
  ratings: { [key: number]: number };
};

export type MultipleChoiceInsights = {
  question: string;
  type: ComplexQuestionTypesEnum.MULTIPLE_CHOICE;
  response_count: number;
  data: MultipleChoiceDataPoint[];
};

export type MultipleChoiceDataPoint = {
  name: string;
  response_count: number;
};

export enum TrendDataTypesEnum {
  SENTIMENT = "sentiment",
}

export type TrendData = {
  type: TrendDataTypesEnum;
  data: LineChartDataSegment[];
};

// Use `category:object_action` naming convention
// `action` should be a present tense verb
export enum PosthogEventTypesEnum {
  PROJECT_CREATE = "projects_list:project_create",
  PROJECT_LAUNCH = "project_configuration:project_launch",
  PARTICIPANTS_INVITE = "project_configuration:participants_invite",
  TEAMMATES_INVITE = "company_settings:teammates_invite",
  TEAMMATE_DOMAIN_LOGIN = "signup_flow:teammate_domainlogin",
  INTERVIEW_START = "interview_app:interview_start",
  INTERVIEW_END = "interview_app:interview_end",
  TRIAL_CONTACT_US = "trial:contactus_click",
  INTERVIEW_UNSUPPORTED_BROWSER = "interview_app:interview_unsupported_browser",
  INTERVIEW_FEEDBACK_SUBMIT = "interview_slideover:feedback_submit",
  INTERVIEW_EMPTY_AUDIO_RESPONSE = "interview_app:empty_audio_response",
}

/*
 * Types.
 */

export interface BasicQuestion {
  question: string;
}

export interface ComplexQuestionOption {
  name: string;
}

export type ProjectQuestion =
  | BasicQuestion
  | ComplexQuestion
  | ComplexQuestionInsert;

export type ComplexQuestionPublic =
  | MatrixQuestionPublic
  | MultipleChoiceQuestionPublic;

type ComplexQuestionPublicBase = {
  id: string;
  question: string;
  options: ComplexQuestionOption[];
};

type MatrixQuestionPublic = ComplexQuestionPublicBase & {
  type: ComplexQuestionTypesEnum.RATING;
  scale: SurveyScalesEnum;
};
export type MatrixInterviewQuestionPublic = MatrixQuestionPublic & {
  has_been_answered: boolean;
};

type MultipleChoiceQuestionPublic = ComplexQuestionPublicBase & {
  type: ComplexQuestionTypesEnum.MULTIPLE_CHOICE;
};

export type MultipleChoiceInterviewQuestionPublic =
  MultipleChoiceQuestionPublic & {
    has_been_answered: boolean;
  };

export type ProjectQuestionPublic = BasicQuestion | ComplexQuestionPublic;

export type ProjectPublic = {
  company_name: string;
  logo_url: string | null;
  redirect_url: string | null;
  incentive_quantity: number | null;
  short_id: string;
  questions: ProjectQuestionPublic[];
  has_sensitive_data: boolean;
  interview_color: string | null;
  welcome_message: string | null;
  mode: ProjectModesEnum | null;
  interview_agent_type: InterviewAgentTypesEnum;
};

export type BasicInterviewQuestion = {
  question: string;
  type: "preset" | "dynamic";
  has_been_answered: boolean;
};

export type ComplexInterviewQuestion =
  | MultipleChoiceInterviewQuestionPublic
  | MatrixInterviewQuestionPublic;

export type InterviewQuestion =
  | BasicInterviewQuestion
  | ComplexInterviewQuestion;

// The interview object that the interview client uses
export type InterviewPublic = {
  id: string;
  questions: InterviewQuestion[];
  status: InterviewStatusEnum;
  contact: BasicContact;
};

export enum TranscriptFragmentSaveStatusEnum {
  IDLE = "idle",
  SAVING = "saving",
  SAVED = "saved",
  ERROR = "error",
}

// Used in the interview client
export type TranscriptFragmentPublicJSON = {
  id: string;
  start_time: string;
  end_time: string;
  question: string | null;
  is_dynamic_question: boolean;
  save_status: TranscriptFragmentSaveStatusEnum;
};

// Used in the admin app client
export type TranscriptFragment = {
  id: string;
  interview_id: string;
  project_id: string;
  start_time: string;
  end_time: string;
  question: string | null;
  is_dynamic_question: boolean;
  dynamic_transcript_fragment_ids: string[];
  save_status: TranscriptFragmentSaveStatusEnum;
  text_transcript: string | null;
  role: TranscriptFragmentRolesEnum | null;
};

export enum LoadingStatesEnum {
  LOADING = "loading",
  LOADED = "loaded",
  ERROR = "error",
}
