import { InterviewCallMetadata } from "app-types";
import { FC, useState } from "react";
import { Label, SizesEnum } from "ui";

interface InterviewRecordingSectionProps {
  recordingIdToUrl: Record<string, string>;
  calls: InterviewCallMetadata[];
}

export const InterviewRecordingSection: FC<InterviewRecordingSectionProps> = ({
  recordingIdToUrl,
  calls,
}) => {
  const [failedUrls, setFailedUrls] = useState<Set<string>>(new Set());

  const orderedRecordingUrls = calls
    .map(
      (call) =>
        call.twilio_recording_id && recordingIdToUrl[call.twilio_recording_id]
    )
    .filter((url): url is string => Boolean(url));

  // Occurs if there's some issue with the URL permissions or internet connection
  const handleAudioError = (url: string) => {
    setFailedUrls((prev) => new Set(prev).add(url));
  };

  const validRecordingUrls = orderedRecordingUrls.filter(
    (url) => !failedUrls.has(url)
  );

  if (validRecordingUrls.length === 0) {
    return null;
  }

  return (
    <div>
      <Label size={SizesEnum.SMALL} className="mt-4 mb-2">
        Audio Recording
      </Label>
      {validRecordingUrls.map((url, index) => (
        <audio
          key={url}
          controls
          src={url}
          onError={() => handleAudioError(url)}
          className={index !== validRecordingUrls.length - 1 ? "mb-3" : ""}
        >
          Your browser does not support the audio element.
        </audio>
      ))}
    </div>
  );
};
