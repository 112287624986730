import { LockClosedIcon } from "@heroicons/react/24/outline";
import {
  Company,
  HandlerOf,
  Project,
  ProjectUpdate,
  convertToValidHttpsUrl,
  getCompanyHasFeatureAccess,
} from "app-types";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Label, SizesEnum, Textarea } from "ui";
import { UpgradeText } from "../../billing/upgradeText";
import { SaveAndCancelButtons } from "../saveAndCancelButtons";

interface AppearanceSettingsSectionProps {
  onSaveProject: HandlerOf<ProjectUpdate>;
  company: Company;
  project: Project;
}

export const AppearanceSettingsSection: FC<AppearanceSettingsSectionProps> = ({
  onSaveProject,
  company,
  project,
}) => {
  const navigate = useNavigate();

  const [welcomeMessage, setWelcomeMessage] = useState(
    project.settings.welcome_message || ""
  );
  const [redirectUrl, setRedirectUrl] = useState(
    project.settings.redirect_url || ""
  );
  const [redirectUrlError, setRedirectUrlError] = useState("");

  const isMissingFeatureAccess = !getCompanyHasFeatureAccess(
    company,
    "whitelabeling"
  );

  const onClickSaveWelcomeMessage = () => {
    onSaveProject({
      id: project.id,
      settings: {
        ...project.settings,
        welcome_message: welcomeMessage || null,
      },
    });
  };

  const onClickSaveRedirectUrl = () => {
    setRedirectUrlError("");

    if (!redirectUrl) {
      onSaveProject({
        id: project.id,
        settings: {
          ...project.settings,
          redirect_url: null,
        },
      });
      return;
    }

    const url = convertToValidHttpsUrl(redirectUrl);

    if (!url) {
      setRedirectUrlError(
        "Invalid URL - please provide a valid URL starting with https://"
      );
      return;
    }

    setRedirectUrl(url);
    onSaveProject({
      id: project.id,
      settings: {
        ...project.settings,
        redirect_url: url,
      },
    });
  };

  return (
    <div>
      <Label size={SizesEnum.LARGE}>Appearance</Label>
      <div className="mt-2 text-sm text-gray-600">
        You can add a custom logo and background color in{" "}
        <span
          className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
          onClick={() => {
            navigate("/settings/organization");
          }}
        >
          organization settings
        </span>
        .
      </div>
      <label className="block text-sm font-semibold leading-6 text-gray-900 mt-2">
        Welcome message
      </label>
      <div className="mt-2 w-[500px]">
        <Textarea
          value={welcomeMessage}
          onChange={(evt) => {
            setWelcomeMessage(evt.target.value);
          }}
          placeholder="We're excited to get your responses!"
          leftIcon={
            isMissingFeatureAccess ? (
              <LockClosedIcon className="h-4 w-4 text-gray-700" />
            ) : undefined
          }
          isDisabled={isMissingFeatureAccess}
          maxLength={500}
        />
      </div>
      {welcomeMessage !== (project.settings.welcome_message || "") ? (
        <SaveAndCancelButtons
          onSave={onClickSaveWelcomeMessage}
          onCancel={() => {
            setWelcomeMessage(project.settings.welcome_message || "");
          }}
        />
      ) : null}
      <div className="mt-2 text-sm text-gray-600">
        A custom greeting (up to 500 characters) to show to participants before
        they start their interview.
        {isMissingFeatureAccess ? (
          <UpgradeText planFeatureName="whitelabeling" />
        ) : null}
      </div>
      <label className="block text-sm font-semibold leading-6 text-gray-900 mt-2">
        Redirect URL
      </label>
      <div className="mt-2">
        <Input
          value={redirectUrl}
          onChange={(evt) => {
            setRedirectUrlError("");
            setRedirectUrl(evt.target.value);
          }}
          placeholder="https://example.com"
          errorDescription={redirectUrlError}
          inputClassName="max-w-[300px]"
          leftIcon={
            isMissingFeatureAccess ? (
              <LockClosedIcon className="h-4 w-4 text-gray-700" />
            ) : undefined
          }
          isDisabled={isMissingFeatureAccess}
          maxLength={200}
        />
      </div>
      {redirectUrl !== (project.settings.redirect_url || "") ? (
        <SaveAndCancelButtons
          onSave={onClickSaveRedirectUrl}
          onCancel={() => {
            setRedirectUrl(project.settings.redirect_url || "");
            setRedirectUrlError("");
          }}
        />
      ) : null}
      <div className="mt-2 text-sm text-gray-600">
        The URL to redirect participants to after an interview is completed.
        {isMissingFeatureAccess ? (
          <UpgradeText planFeatureName="whitelabeling" />
        ) : null}
      </div>
    </div>
  );
};
