import { CompanyInvitation, LoadingStatesEnum, isValidDomain } from "app-types";
import { FC, useEffect, useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  DefaultSkeleton,
  SearchableSelect,
  SearchableSelectModesEnum,
  SearchableSelectOption,
} from "ui";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  fetchCompanyDomains,
  selectCompanyDomainsState,
  updateCompanyDomains,
} from "../../company/companyDomainsSlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { selectTeammateLoadingStatus } from "../../teammate/teammateSlice";
import {
  fetchTeammates,
  selectAllTeammates,
} from "../../teammates/teammatesSlice";
import { InviteTeammatesSection } from "./inviteTeammatesSection";
import { TeammatesTable } from "./teammatesTable";

export const TeammatesSettingsSection: FC = () => {
  const dispatch = useAppDispatch();
  const teammates = useAppSelector((state) =>
    selectAllTeammates(state.teammates)
  );
  const teammatesLoadingState = useAppSelector(selectTeammateLoadingStatus);
  const {
    domains: companyDomains,
    status: companyDomainsLoadingState,
    error: companyDomainsError,
  } = useAppSelector(selectCompanyDomainsState);

  const [listInvitationsLoadingState, setListInvitationsLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);

  const [pendingInvitations, setPendingInvitations] = useState<
    CompanyInvitation[]
  >([]);

  const [localDomains, setLocalDomains] = useState<string[]>([]);

  useEffect(() => {
    setLocalDomains(companyDomains);
  }, [companyDomains]);

  /*
   * Initial render methods
   */

  const fetchInvitations = async () => {
    setListInvitationsLoadingState(LoadingStatesEnum.LOADING);
    try {
      const axios = await getAxiosInstanceWithAuth();
      const { data } = await axios.get(
        `/company-invitations/pending-invitations`
      );

      setPendingInvitations(data);
      setListInvitationsLoadingState(LoadingStatesEnum.LOADED);
    } catch (err) {
      setListInvitationsLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  useEffect(() => {
    dispatch(fetchTeammates());
    dispatch(fetchCompanyDomains());
    fetchInvitations();
  }, []);

  /*
   * Action handlers
   */

  const onDeleteInvitation = async (invitationId: string) => {
    setListInvitationsLoadingState(LoadingStatesEnum.LOADING);
    try {
      const axios = await getAxiosInstanceWithAuth();
      const { data } = await axios.delete(
        `/company-invitations/${invitationId}`
      );

      // Remove the invitation from the list
      const newInvitations = pendingInvitations.filter(
        (invitation) => invitation.id !== invitationId
      );
      setPendingInvitations(newInvitations);

      setListInvitationsLoadingState(LoadingStatesEnum.LOADED);
    } catch (err) {
      setListInvitationsLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  const saveCompanyDomains = async () => {
    try {
      await dispatch(updateCompanyDomains(localDomains)).unwrap();

      showNotification(dispatch, {
        id: `approved-domains-updated-${new Date().getTime()}`,
        primaryMessage: `Approved domains successfully updated`,
        type: NotificationTypeEnum.SUCCESS,
      });
    } catch (err) {
      console.error("Error updating company domains:", err);
    }
  };

  /*
   * Component render
   */

  return (
    <div className="max-w-2xl min-w-[450px] mx-auto px-8">
      <div className="border-b border-gray-900/10 py-4">
        <h2 className="text-lg pb-4 font-semibold leading-6 text-gray-900">
          Invite teammates
        </h2>
        <InviteTeammatesSection
          onInviteTeammates={() => {
            fetchInvitations();
          }}
        />
      </div>

      <div className="border-b border-gray-900/10 py-4 w-full">
        <h2 className="text-lg font-semibold leading-6 text-gray-900 mb-4">
          Manage teammates
        </h2>
        <div className="text-sm pb-4 text-gray-600">
          Your plan includes unlimited teammates.
        </div>
        <TeammatesTable
          teammates={teammates}
          pendingInvitations={pendingInvitations}
          onDeleteInvitation={onDeleteInvitation}
          isLoading={
            listInvitationsLoadingState === LoadingStatesEnum.LOADING ||
            teammatesLoadingState === LoadingStatesEnum.LOADING
          }
          hasError={
            listInvitationsLoadingState === LoadingStatesEnum.ERROR ||
            teammatesLoadingState === LoadingStatesEnum.ERROR
          }
        />
      </div>

      <div className="border-b border-gray-900/10 py-4 w-full">
        <h2 className="text-lg font-semibold leading-6 text-gray-900 mb-4">
          Approved signup domains
        </h2>
        {companyDomainsLoadingState === LoadingStatesEnum.LOADING ? (
          <DefaultSkeleton />
        ) : (
          <>
            <SearchableSelect
              mode={SearchableSelectModesEnum.MULTI}
              placeholder="Add a domain (e.g. acme.com)"
              selectedOptions={localDomains.map((e) => {
                return {
                  id: e,
                  name: e,
                };
              })}
              options={[]}
              onChange={(options: SearchableSelectOption[]) => {
                setLocalDomains(
                  options.map((o) => o.id).filter((e) => isValidDomain(e))
                );
              }}
              description="Users who sign up with a matching email domain will be 
              able to join your organization without a direct invite.
              To add more than one domain, separate each with a comma."
              errorDescription={companyDomainsError || undefined}
            />
            <div className="mt-4">
              <Button
                variant={ButtonVariantsEnum.Primary}
                label="Save"
                onClick={saveCompanyDomains}
                isDisabled={
                  JSON.stringify(localDomains) ===
                  JSON.stringify(companyDomains)
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
