import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Interview,
  InterviewFeedbackRatingsEnum,
  PosthogEventTypesEnum,
} from "app-types";
import posthog from "posthog-js";
import { FC, useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  Modal,
  RadioButtonsGroup,
  SizesEnum,
  Textarea,
} from "ui";
import { useAppDispatch } from "../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { bulkUpdateInterviews } from "../interviewsSlice";

// Max of 600 characters of freeform feedback.
const MAX_FEEDBACK_LENGTH = 600;

interface InterviewFeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
  interview: Interview;
}

export const InterviewFeedbackModal: FC<InterviewFeedbackModalProps> = ({
  isOpen,
  onClose,
  interview,
}) => {
  const [rating, setRating] = useState<InterviewFeedbackRatingsEnum | null>(
    interview.metadata?.feedback?.rating || null
  );
  const [feedbackText, setFeedbackText] = useState<string>(
    interview.metadata?.feedback?.text || ""
  );
  const dispatch = useAppDispatch();

  const onSubmitFeedback = () => {
    if (!rating) return;

    dispatch(
      bulkUpdateInterviews({
        interviewIds: [interview.id],
        update: {
          metadata: {
            ...interview.metadata,
            feedback: {
              rating: rating,
              text: feedbackText,
            },
          },
        },
      })
    );

    onClose();

    posthog.capture(PosthogEventTypesEnum.INTERVIEW_FEEDBACK_SUBMIT, {
      interview_id: interview.id,
      feedback: {
        rating: rating,
        text: feedbackText,
      },
    });

    showNotification(dispatch, {
      id: `interview-${
        interview.id
      }-feedback-submitted-${new Date().getTime()}`,
      primaryMessage: `Feedback saved`,
      type: NotificationTypeEnum.SUCCESS,
    });
  };

  const isSubmitDisabled = () => {
    if (!rating) return true;

    // Freeform input is not required if the feedback is that the interview was great.
    if (rating === InterviewFeedbackRatingsEnum.GREAT) return false;

    if (feedbackText.length > MAX_FEEDBACK_LENGTH) {
      return true;
    }

    return !feedbackText;
  };

  const maybeRenderInput = () => {
    // Only ask for freeform feedback if the rating was not "Great"
    if (!rating) return null;

    return (
      <div className="w-full mt-5">
        <div className="block text-sm font-medium text-gray-900 my-2">
          Please add details below.
        </div>
        <Textarea
          value={feedbackText}
          placeholder={
            rating === InterviewFeedbackRatingsEnum.GREAT
              ? "The clarifying questions asked about competitors were especially helpful, keep asking those..."
              : "When the participant mentioned that they also evaluated our competitor Acme, I wanted Alpharun to ask about their workflows feature..."
          }
          onChange={(event) => {
            setFeedbackText(event.target.value);
          }}
          isDisabled={Boolean(interview.metadata?.feedback?.text)}
        />
      </div>
    );
  };

  const maybeRenderButtons = () => {
    // If we've already submitted feedback, we're in view-only mode so no buttons necessary.
    if (interview.metadata?.feedback?.rating) return null;

    return (
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <div className="ml-3">
          <Button
            variant={ButtonVariantsEnum.Primary}
            label="Submit"
            isDisabled={isSubmitDisabled()}
            onClick={onSubmitFeedback}
          />
        </div>
        <Button
          variant={ButtonVariantsEnum.Secondary}
          label="Cancel"
          onClick={onCancelOrClose}
        />
      </div>
    );
  };

  const onCancelOrClose = () => {
    if (!interview.metadata?.feedback || !interview.metadata?.feedback.rating) {
      setRating(null);
      setFeedbackText("");
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onCancel={onCancelOrClose}>
      <div className="flex flex-row justify-between">
        <Dialog.Title
          as="h3"
          className="text-xl font-semibold leading-6 text-gray-900"
        >
          Share interview feedback
        </Dialog.Title>
        <XMarkIcon
          className="h-5 w-5 cursor-pointer"
          aria-hidden="true"
          onClick={onCancelOrClose}
        />
      </div>

      <div className="text-sm text-gray-700 mt-2">
        Share feedback to improve the clarifying questions that Alpharun asks
        over time.
      </div>

      <div className="text-sm font-medium text-gray-900 my-2">
        How would you rate this interview's clarifying questions?
      </div>

      <RadioButtonsGroup
        value={rating}
        onChange={(value) => setRating(value as InterviewFeedbackRatingsEnum)}
        size={SizesEnum.MEDIUM}
        isDisabled={Boolean(interview.metadata?.feedback?.rating)}
        options={[
          {
            name: InterviewFeedbackRatingsEnum.GREAT,
            description:
              "Clarifying questions were useful follow-ups to the feedback the participant provided.",
          },
          {
            name: InterviewFeedbackRatingsEnum.REPETITIVE,
            description:
              "Clarifying questions were occasionally repetitive of topics already covered by the participant.",
          },
          {
            name: InterviewFeedbackRatingsEnum.MISSING_FOCUS_AREAS,
            description:
              "Clarifying questions didn't always dig into the areas that would have been most helpful.",
          },
          {
            name: InterviewFeedbackRatingsEnum.OTHER,
          },
        ]}
      />

      {maybeRenderInput()}

      {maybeRenderButtons()}
    </Modal>
  );
};
